import React, { useState } from 'react';
import { Button } from '../../../../common/components';
import { Layout } from '../../../../common/components/Layout';
import styled from "styled-components";
import animationData from './success.json';
import Lottie, { Options } from 'react-lottie';

interface Props {

}



export const Entry: React.FC<Props> = (props: Props) => {
    const { } = props;
    const [showLottie, setShowLottie] = useState(true);

    const defaultOptions: Options = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        
    };

    return (
        <Layout fullPage={true}>
            <Content>
                <Title>Awesome!</Title>
                <p>
                    Welcome to Charlie!<br/>
                    So happy to have you here with us :)
                </p>
                {
                    showLottie && (
                        <AnimationContainer>
                            <Lottie
                            style={{height: "100vh"}}
                                options={defaultOptions}
                                isClickToPauseDisabled={true}
                                eventListeners={[
                                    {
                                        eventName: 'complete',
                                        callback: () => {
                                            setShowLottie(false);
                                        },
                                    },
                                ]}
                            />
                        </AnimationContainer>
                    )
                }
                <Actions>
                    <Button href="/">Back to home page</Button>
                </Actions>
            </Content>
        </Layout>
    );
}

const Content = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 24px;

    p {
        font-size: 1.1rem;
        color: #987643;
    }
`

const Title = styled.h1`
`;

const Actions = styled.div`
    margin-top: 2rem;
    position: relative;
    z-index: 3;
`;

const AnimationContainer = styled.div`
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    /* top: 50%;
    transform: translateY(-50%); */
`;