import React, { useState } from 'react';
import styled from 'styled-components';
import { Layout } from '../../../../common/components/Layout';
import { signup } from '../../api';
import { Option } from '../../../../common/components/CitySearch/glossary';
import { useNavigate } from 'react-router-dom';
import { startSession } from '../../../../common/auth/accessToken';
import { Stepper } from '../../../../common/components/Stepper';
import { DisplayNameStep } from './steps/DisplayNameStep';
import { Step } from '../../../../common/components/Stepper/glossary';
import { SelfIntroductionStep } from './steps/SelfIntroductionStep';
import { ContactInfoStep } from './steps/ContactInfoStep';
import { ProfilePictureStep } from './steps/ProfilePictureStep';
import { CredentialsStep } from './steps/CredentialsStep';
import { useSuite, ValidationOptions } from './suite';
import { JoinFormData } from './glossary';
import { SuiteRunResult } from 'vest';
import { TABS } from './consts';
import { WhatsCharlieStep } from './steps/Onboarding/WhatsCharlieStep';
import { ConnectingFriendsStep } from './steps/Onboarding/ConnectingFriendsStep';
import { FriendExampleStep } from './steps/Onboarding/FriendExampleStep';
import { FriendModel } from '../../model/friend.model';
import { InvitationModel } from '../../model/invitation.model';
import { BecomePartOfTheCommunityStep } from './steps/Onboarding/BecomePartOfTheCommunityStep';

interface Props {
    invitationId: string;
    invitation: InvitationModel;
}

enum Status {
    UNSET,
    SUCCESS,
    FAILURE
}

const friend2: FriendModel = {
    displayName: "Ben",
    image: "https://res.cloudinary.com/dzsptpetn/image/upload/v1669635919/charlie/profile-pictures/image_3_dguz50.png",
    location: {
        country: {
            name: "France",
            countryCode: "FR"
        },
        city: {
            id: 1,
            name: "Paris"
        }
    },
    superpower: "clubs"
}

export const SignupForm: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const suite = useSuite();
    const { invitationId, invitation } = props;
    const [status, setStatus] = useState(Status.UNSET);
    const [validationResult, setValidationResult] = useState<SuiteRunResult | undefined>();

    const [displayName, setDisplayName] = useState('');

    const [selectedCountryOption, setSelectedCountryOption] = useState<undefined | Option>();
    const [selectedCityOption, setSelectedCityOption] = useState<undefined | Option>();

    const [image, setImage] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [selfIntroduction, setSelfIntroduction] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [instagram, setInstagram] = useState('');

    const formData: Partial<JoinFormData> = {
        displayName,
        country: selectedCountryOption,
        city: selectedCityOption,
        selfIntroduction,
        phoneNumber,
        instagram,
        profilePicture: image,
        email,
        password
    }

    const onSubmit = async () => {
        try {
            const { accessToken } = await signup({
                invitationId,
                email,
                password,
                displayName,
                image,
                cityId: parseInt(selectedCityOption.value),
                selfIntroduction,

                phoneNumber,
                instagram
            });

            startSession(accessToken);
            setStatus(Status.SUCCESS);
            navigate("/success");
        } catch {
            setStatus(Status.FAILURE);
        }
    }
    if (status === Status.FAILURE) {
        return <div>Something went wrong</div>
    }

    const validate = (formData: Partial<JoinFormData & ValidationOptions>, field?: string): SuiteRunResult => {
        const result = suite(formData, field);
        setValidationResult(result);
        return result;
    }

    const validateStep = (data: Partial<JoinFormData>, tab: string): SuiteRunResult => {
        return validate({ ...data, tab });
    }

    const isTabValid = (data: Partial<JoinFormData>, tab: string): boolean => {
        const res = validateStep(data, tab);
        return res.isValidByGroup(tab);
    }

    const validateDisplayNameStep = (): boolean => {
        return isTabValid(formData, TABS.DISPLAY_NAME);
    }

    const validateSelfIntroductionStep = (): boolean => {
        return isTabValid(formData, TABS.SELF_INTRODUCTION);
    }

    const validateContactInfoStep = (): boolean => {
        return isTabValid(formData, TABS.CONTACT_INFO);
    }

    const validateProfilePictureStep = (): boolean => {
        return isTabValid(formData, TABS.PROFILE_PICTURE);
    }

    const validateCredentialsStep = (): boolean => {
        return isTabValid(formData, TABS.CREDENTIALS);
    }

    const steps: Step[] = [
        {
            submitButtonText: "Continue",
            component: <WhatsCharlieStep />
        },
        {
            submitButtonText: "Continue",
            component: <ConnectingFriendsStep invitation={invitation} />
        },
        {
            submitButtonText: "Continue",
            component: <FriendExampleStep friend={invitation.friends[0]} key={1} />
        },
        {
            submitButtonText: "Continue",
            component: <FriendExampleStep friend={invitation.friends[1]} key={2} />,
            skip: (invitation.friends[1]) === undefined
        },
        {
            submitButtonText: "Sounds Good!",
            component: <BecomePartOfTheCommunityStep />
        },
        {
            submitButtonText: 'Next',
            component: (
                <DisplayNameStep
                    displayName={displayName}
                    onDisplayNameChange={setDisplayName}
                    selectedCountryOption={selectedCountryOption}
                    onSelectedCountryOptionChange={setSelectedCountryOption}
                    selectedCityOption={selectedCityOption}
                    onSelectedCityOptionChange={setSelectedCityOption}
                    validate={validate}
                    validationResult={validationResult}
                />
            ),
            beforeSubmit: () => validateDisplayNameStep()
        },
        {
            submitButtonText: 'Next',
            component: (
                <SelfIntroductionStep
                    selfIntroduction={selfIntroduction}
                    onSelfIntroductionChange={setSelfIntroduction}
                    validate={validate}
                    validationResult={validationResult}
                />
            ),
            beforeSubmit: () => validateSelfIntroductionStep()
        },
        {
            submitButtonText: 'Next',
            component: (
                <ContactInfoStep
                    phoneNumber={phoneNumber}
                    onPhoneNumberChange={setPhoneNumber}
                    instagram={instagram}
                    onInstagramChange={setInstagram}
                    validate={validate}
                    validationResult={validationResult}
                />
            ),
            beforeSubmit: () => validateContactInfoStep()
        },
        {
            submitButtonText: 'Next',
            component: (
                <ProfilePictureStep
                    image={image}
                    onImageChange={setImage}
                    validate={validate}
                    validationResult={validationResult}
                />
            ),
            beforeSubmit: () => validateProfilePictureStep()
        },
        {
            submitButtonText: 'Submit!',
            component: (
                <CredentialsStep
                    email={email}
                    onEmailChange={setEmail}
                    password={password}
                    onPasswordChange={setPassword}
                    validate={validate}
                    validationResult={validationResult}
                />
            ),
            beforeSubmit: () => validateCredentialsStep()
        }
    ]

    return (
        <Layout>
            <Form>
                <Stepper steps={steps} onComplete={onSubmit} />
            </Form>
        </Layout>
    );
}

const Form = styled.div`
    margin: 0 16px;
    margin-top: 24px;
`
