import React from 'react';
import styled from "styled-components";

interface Props {
    src: string;
    title: string;
}

export const ProfilePicture = styled.div`
    border-radius: 100%;
    overflow: hidden;
    background: url(${(props: Props) => props.src}) no-repeat #eece9d;
    background-size: auto 100%;
    background-position: 50% 50%;

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
` as React.FC<Props>;