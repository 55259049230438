import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { AuthContext } from './common/components/AuthContext';
import { LoginPage, HomePage, CityPage, ProfilePage, JoinPage, SuccessPage, MapPage } from './pages';
import { NotificationsPage } from './pages/notifications-page';

function App() {
  return (
    <AuthContext>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="city/:id" element={<CityPage />} />
        <Route path="profile/:id" element={<ProfilePage />} />
        <Route path="invite" element={<JoinPage />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="map" element={<MapPage />} />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route path="" element={<HomePage />} />
      </Routes>
    </AuthContext>
  );
}

export default App;
