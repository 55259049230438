import React, { useState } from 'react';
import styled from "styled-components";

interface Props<T> {
    pageSize: number;
    values: T[];
    getKey: (value: T) => any;
    component: React.FC<{ key: any; value: T }>;
}

export const ShowMore: React.FC<Props<any>> = (props: Props<any>) => {
    const { pageSize, values, getKey, component: Component } = props;
    const [page, setPage] = useState(0);
    const totalPages = Math.ceil(values.length / pageSize);
    const numberOfValuesToShow = (page + 1) * pageSize;
    const valuesToShow = values.slice(0, numberOfValuesToShow);
    const hasMoreToShow = page + 1 < totalPages;

    const showMore = () => {
        setPage(p => p + 1);
    }

    return (
        <>
            {
                valuesToShow.map(val => {
                    const key = getKey(val);
                    return <Component key={key} value={val} />
                })
            }
            {hasMoreToShow && (
                <Footer>
                    <ShowMoreButton onClick={showMore}>Show More</ShowMoreButton>
                </Footer>
            )}

        </>
    );
}

const Footer = styled.div`
    text-align: center;
    margin-top: 1rem;
`;

const ShowMoreButton = styled.span`
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
`;