import axios from "axios";
import { authorizationHeaders } from "../../../apis/auth";
import { Urls } from "../../../apis/urls";
import { stopSession } from "../../../auth/accessToken";
import { ContextModel } from "../model/context.model";
import { GetContextResponse, toContextModel } from "./contracts/get-context.response";

export async function fetchContext(): Promise<ContextModel | null> {
    const config = authorizationHeaders();

    if (!config) {
        return null;
    }

    try {
        const { data } = await axios.get<GetContextResponse>(Urls.getContext(), config);
        return toContextModel(data);
    } catch {
        stopSession();
        return null;
    }
}