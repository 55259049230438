import axios from "axios";
import { authorizationHeaders } from "../../../common/apis/auth";
import { Urls } from "../../../common/apis/urls";
import { FriendRequestModel } from "../model/friend-request.model";
import { GetFriendRequestsResponse, toFriendRequestModels } from "./contracts/get-friend-requests.response";

export async function getFriendRequests(authenticatedUserId: string): Promise<FriendRequestModel[]> {
    const { data } = await axios.get<GetFriendRequestsResponse>(Urls.getFriendRequestsByRecipient(authenticatedUserId), authorizationHeaders());
    return toFriendRequestModels(data);
}

export async function approveFriendRequest(friendRequest: FriendRequestModel): Promise<void> {
    await axios.put(Urls.acceptFriendRequest(friendRequest.id), {}, authorizationHeaders());
}