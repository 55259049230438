import { CityModel } from "../../../models/city.model";
import { FriendModel } from "../../../models/friend.model";

export interface DataViewResult {
    city: CityContract;
}

interface CityContract {
    id: string;
    name: string;
    friends: FriendContract[];
    friendsOfFriends: FriendContract[];
}

interface FriendContract {
    id: string;
    displayName: string;
    image: string;
}

export function toCityModel(contract: DataViewResult): CityModel {
    const { city } = contract;

    return {
        id: city.id,
        name: city.name,
        friends: city.friends.map(toFriendModel),
        friendsOfFriends: city.friendsOfFriends.map(toFriendModel)
    }
}

function toFriendModel(contract: FriendContract): FriendModel {
    return {
        id: contract.id,
        name: contract.displayName,
        image: contract.image
    }
}