import React, { useState } from 'react';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { getSmallImageUrl } from '../../../../common/image';
import { FriendRequestModel } from '../../model/friend-request.model';
import styled from "styled-components";
import { ActionButton } from '../../../../common/components/ActionButton';
import { approveFriendRequest } from '../../apis';
import { Button } from '../../../../common/components';
import { CheckMarkIcon } from '../../../../common/components/icons/CheckMarkIcon';
import { UnstyledLink } from '../../../../common/components/UnstyledLink';
import { ButtonSize } from '../../../../common/components/Button';
import { useStats } from '../../../../common/stats';

interface Props {
    friendRequest: FriendRequestModel;
}

export const FriendRequest: React.FC<Props> = (props: Props) => {
    const { friendRequest } = props;
    const { sender } = friendRequest;
    const [approved, setApproved] = useState(false);
    const stats = useStats();
    const senderProfileUrl = `/profile/${friendRequest.sender.id}`;

    const onClick = async () => {
        await approveFriendRequest(friendRequest);
        stats.trackEvent("Friend Request Approved", { friendRequestId: friendRequest.id });
    }

    return (
        <Container>
            <UnstyledLink to={senderProfileUrl}>
                <PictureContainer>
                    <ProfilePicture src={getSmallImageUrl(sender.image, 50)} title={sender.displayName} />
                </PictureContainer>
            </UnstyledLink>
            <UnstyledLink style={{ flex: 1 }} to={senderProfileUrl}>
                {friendRequest.sender.displayName}
            </UnstyledLink>
            <div>
                {
                    approved ? (
                        <SuccessButton
                            size={ButtonSize.SMALL}
                        >
                            <ButtonIconContainer>
                                <CheckMarkIcon />
                            </ButtonIconContainer>
                            Friends
                        </SuccessButton>
                    ) : (
                        <ActionButton
                            onClick={onClick}
                            onSuccess={() => setApproved(true)}
                            size={ButtonSize.SMALL}
                        >
                            Approve
                        </ActionButton>
                    )
                }

            </div>
        </Container >
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    background: #fff1d9;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

    & + & {
        margin-top: 8px;
    }
`;

const PictureContainer = styled.div`
    width: 48px;
    margin-right: 16px;
`;

const ButtonIconContainer = styled.div`
    font-size: 0.9em;
    display: inline-block;
    margin-right: 1ch;
    position: relative;
    top: 1px;
`;

const SuccessButton = styled(Button)`
    background: #cfb993 !important;
    color: #382910 !important;
    box-shadow: unset !important;
    border-top-color: #c0a77c;
    border-bottom-color: #f7e5c6;
`;
