import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from "../../components/AuthContext";
import { Loading } from "../../components/Loading";

export function withAuthGuard<T>(Component: React.FC<T>): React.FC<T> {
    const SafeComponent: React.FC<T> = (props: T) => {
        const { userId } = useAuthContext();
        const navigate = useNavigate();

        useEffect(() => {
            if (!userId) {
                navigate('/')
            }

        }, [navigate, userId])

        if (!userId) {
            return <Loading />;
        }

        return <Component {...props} />;
    }

    return SafeComponent;
}