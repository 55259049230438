import React from 'react';
import { Link } from 'react-router-dom';
import { RecommendationModel } from '../../models/recommendation.model';
import styled from "styled-components";
import { FlagIcon } from '../../../../common/components/icons/FlagIcon';

interface Props {
    recommendation: RecommendationModel;
}

export const Recommendation: React.FC<Props> = (props: Props) => {
    const { recommendation } = props;
    const { country, city } = recommendation;
    const text = `${city.name}, ${country.name}`;

    const { connections } = recommendation
    const recommendationText = connections > 1 ? `${connections} friends` : `1 friend`;

    return (
        <StyledLink to={`/city/${city.id}?source=logged-in-home-page&source-element=recommendations`}>
            <Icon>
                <FlagIcon countryCode={country.countryCode} title={`${country.name} Flag`} size={28} />
            </Icon>
            <Text>{text}</Text>
            <Chip>{recommendationText}</Chip>
            <Spacer />
        </StyledLink>
    );
}

const StyledLink = styled(Link)`
    margin: .8rem 0;
    text-decoration: none;
    color: #503812;
    display: flex;
    align-items: center;
`;

const Icon = styled.span`
    margin-right: 1em;
    margin-top: 3px;
    display: inline-block;
`;

const Text = styled.span`
    vertical-align: middle;
    font-weight: 600;
`;

const Chip = styled.span`
    border-radius: 50px;
    color: #432f0e;
    background: #edcd95;
    margin-left: 1rem;
    font-size: .7rem;
    padding: .5ch 1ch;
    flex-shrink: 0;
`;

const Spacer = styled.div`
    flex: 1; 
`;
