export function authorizationHeaders() {
    const accessToken = getLocalStorage().getItem('access_token');

    if (!accessToken) {
        return null;
    }

    return {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
        }
    };
}


function getLocalStorage() {
    return window.localStorage;
}
