import mixpanel from 'mixpanel-browser';
import React, { useCallback, useMemo } from 'react';
import { useAuthContext } from '../components/AuthContext';
import { isDev } from "../env";
import _ from 'lodash';

export function configure() {
    if (isDev) {
        return;
    }

    const token = process.env.REACT_APP_MIXPANEL_TOKEN ?? '';

    if (!token) {
        throw Error("Could not initialize mixpanel client. Token not present.");
    }

    mixpanel.init(token);
}

export function trackEvent(eventName: string, payload: object = {}) {
    if (isDev) {
        console.log(`[TRACK] ${eventName}`, payload);
        return;
    }

    mixpanel.track(eventName, payload);
}

interface MixpanelHook {
    trackEvent: (eventName: string, payload?: object) => void;
}

export const useStats = (): MixpanelHook => {
    const { userId } = useAuthContext();

    const enrichedTrackEvent = useCallback((eventName: string, payload: object = {}) => {
        const enrichedPayload = _.merge(
            {}, { authenticatedUserId: userId }, payload
        );

        trackEvent(eventName, enrichedPayload);
    }, [userId]);

    const memoizedHook = useMemo((): MixpanelHook => ({
        trackEvent: enrichedTrackEvent
    }), [enrichedTrackEvent]);

    return memoizedHook;
}
