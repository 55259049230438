import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { config } from '../../../../common/config';
import { isDev } from '../../../../common/env';
import styled from "styled-components";
import { CloudinaryFileUploadResult } from './glossary';
import { getSmallImageUrl } from '../../../../common/image';

interface Props {
    id: string;
    value: string;
    onChange: (value: string) => any;
}

export const FileUploader: React.FC<Props> = (props: Props) => {
    const { id, value: imageUrl, onChange: setImageUrl } = props;
    const [file, setFile] = useState();

    const handleFileInputChange = (e) => {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        async function upload() {
            if (!file) {
                return;
            }

            setImageUrl("");
            const img = await uploadFile(file);
            setImageUrl(img);
        }

        upload();
    }, [file])

    return (
        <div>
            <input
                id={id}
                type="file"
                onChange={handleFileInputChange}
            />
            <ImagePreview>
                <ProfilePicture src={getSmallImageUrl(imageUrl, 300)} title={imageUrl ? "Your profile picture" : ""} />
            </ImagePreview>
        </div>
    );
}

const unsignedUploadPreset = "jvhbdhk8";
const folderName = isDev ? "charlie-dev/profile-pictures" : "charlie/profile-pictures";


export async function uploadFile(file: File): Promise<string> {
    const url = config.cloudinary.uploadUrl;

    const formData = new FormData();

    formData.append("upload_preset", unsignedUploadPreset);
    formData.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    formData.append("folder", folderName);
    formData.append("file", file);

    const { data } = await axios({
        method: "POST",
        url,
        data: formData,
        headers: {
            "X-Requested-With": "XMLHttpRequest"
        }
    })

    return (data as CloudinaryFileUploadResult).secure_url;
}

const ImagePreview = styled.div`
    margin-top: 1rem;
    width: 60vw;
`;
