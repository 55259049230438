import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useAuthContext } from '../../AuthContext';
import { Portal } from '../../Portal';
import { FeatureFlag } from '../../../feature-flag';
import { FEATURES } from '../../../feature-flag/features';
import { NotificationsPreviewModel } from './models/notifications-preview.model';
import { getNotificationsPreview } from './apis';
import { UnstyledLink } from '../../UnstyledLink';

interface Props {
    children: React.ReactNode;
}

export const Menu: React.FC<Props> = (props: Props) => {
    const { children } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { userId } = useAuthContext();

    const friendRequestsFeatureIsEnabled = FeatureFlag.isEnabled(FEATURES.FRIEND_REQUESTS);
    const [notifications, setNotifications] = useState<undefined | NotificationsPreviewModel>();
    const showRedDot = (notifications?.count ?? 0) > 0;

    const openMenu = () => setIsOpen(true);
    const closeMenu = () => setIsOpen(false);

    useEffect(() => {
        async function init() {
            const np = await getNotificationsPreview();
            setNotifications(np);
        }

        if (friendRequestsFeatureIsEnabled) {
            init()
        }
    }, [])

    return (
        <>
            <InvisibleButton aria-label="Menu" onClick={openMenu}>
                {children}
                {showRedDot && <RedDot data-testid="porfile-picture-red-dot" />}
            </InvisibleButton>

            <Portal>
                <AnimatePresence>
                    {
                        isOpen && (
                            <>
                                <Container
                                    initial={{ scale: "0%", opacity: 0, x: "-50%", y: "-50%" }}
                                    animate={{ scale: "100%", opacity: 1, x: "-50%", y: "-50%" }}
                                    exit={{ scale: "0%", opacity: 0, x: "-50%", y: "-50%" }}
                                    transition={{ duration: .2, type: "easeIn" }}
                                >
                                    <Item>
                                        <UnstyledLink to={`/profile/${userId}`} onClick={closeMenu}>
                                            <ItemContent>View Profile</ItemContent>
                                        </UnstyledLink>
                                    </Item>
                                    {friendRequestsFeatureIsEnabled && (
                                        <Item>
                                            <UnstyledLink to={`/notifications`} onClick={closeMenu}>
                                                {showRedDot && <MenuRedDot data-testid="menu-red-dot" />}
                                                <ItemContent>Notifications</ItemContent>
                                            </UnstyledLink>
                                        </Item>
                                    )}
                                    <Item
                                        style={{ fontWeight: 400, color: "#8d734a" }}
                                    >
                                        <ItemContent onClick={closeMenu}>Close</ItemContent>
                                    </Item>
                                </Container>

                                <Overlay
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: .5 }}
                                    transition={{ duration: .2, type: "easeIn" }}
                                    exit={{ opacity: 0 }}
                                    onClick={closeMenu}
                                />
                            </>
                        )
                    }
                </AnimatePresence>
            </Portal>
        </>
    );
}

const InvisibleButton = styled.button`
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    font-family: unset;
    color: unset;
`;

const Container = styled(motion.div)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 5;
    width: 200px;
    box-shadow: 0 2px 3px 0 #997e56, 0 7px 30px 7px #d3b68b;
    border-radius: 6px;
`;

const Item = styled.div`
    background: #fcdfaf;
    font-weight: 600;
    color: #432e0c;

    &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    & + & {
        border-top: 1px solid #e6cca2;
    }
`;

const Overlay = styled(motion.div)`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 4;
    
    background: #dbc5a0;
`;

const ItemContent = styled.div`
    display: inline-block;
    width: 100%;
    padding: 14px;
    text-align: center;
`;

const RedDot = styled.div`
    border-radius: 100%;
    background: radial-gradient( at top right, #dd99ff, #9f00c7);
    position: absolute;
    top: -2px;
    right: -2px;
    width: 16px;
    height: 16px;
    z-index: 2;
    border: 2px solid rgb(255, 223, 175);
`;

const MenuRedDot = styled(RedDot)`
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
`;