import classNames from 'classnames';
import React from 'react';
import { Validations } from '../Validations';
import classes from './index.module.scss';

interface Props {
    value?: string;
    type?: string;
    onChange?: (value: string) => any;
    id?: string;
    disabled?: boolean;
    placeholder?: string;
    onBlur?: () => any;
    errors?: string[];
    icon?: React.ReactNode;
}

export const Input: React.FC<Props> = (props: Props) => {
    const {
        value,
        onChange,
        errors,
        icon,
        ...otherProps
    } = props;

    const hasErrors = errors && errors.length > 0;
    const clazz = classNames(classes.input, { [classes.error]: hasErrors }, { [classes.withIcon]: !!icon })

    return (
        <>
            <span className={classes.container}>
                <input
                    type={props.type ?? "text"}
                    className={clazz}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    {...otherProps}
                />
                {!!icon && (
                    <span className={classes.iconContainer}>
                        {icon}
                    </span>
                )}
            </span>
            <Validations errors={errors} />
        </>
    );
}
