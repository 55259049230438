import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { acceptFriendshipRequest, fetchInvitation } from '../../api';
import { InvitationModel } from '../../model/invitation.model';
import { Layout } from '../../../../common/components/Layout';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { SignupForm } from '../SignupForm';
import { InvitationPreview } from '../InvitationPreview';
import { useStats } from '../../../../common/stats';

enum Status {
    UNSET,
    SUCCESS,
    FAILURE
}

interface Props {
}

export const Entry: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const stats = useStats();
    const [searchParams] = useSearchParams();
    const { userId } = useAuthContext();
    const invitationId = searchParams.get('token');
    const [invitation, setInvitation] = useState<InvitationModel | undefined>();
    const [status, setStatus] = useState(Status.UNSET);
    const [showSignupForm, setShowSignupForm] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const data = await fetchInvitation(invitationId);
            setInvitation(data);
        }

        stats.trackEvent("Invitation Page Viewed", { invitationId: invitationId })
        fetchData();
    }, [invitationId])

    if (!invitation) {
        return null;
    }

    if (status === Status.FAILURE) {
        return <div>Something went wrong</div>
    }

    if (showSignupForm) {
        return <SignupForm invitationId={invitationId} invitation={invitation} />
    }

    const onClick = async () => {
        if (!userId) {
            setShowSignupForm(true);
            return;
        }

        try {
            await acceptFriendshipRequest(invitationId)
            setStatus(Status.SUCCESS);
            navigate('/success');
        } catch {
            setStatus(Status.FAILURE)
        }
    };

    return (
        <Layout>
            <InvitationPreview
                invitation={invitation}
                onClick={onClick}
            />
        </Layout>
    );
}
