import React from 'react';
import { motion } from "framer-motion"
import styled from "styled-components";
import { ProfilePicture } from '../../../../../../common/components/ProfilePicture';
import { getSmallImageUrl } from '../../../../../../common/image';

interface Props {

}

const duration = 0.7;
const easingMode = "spring"

const friendImage1 = "https://res.cloudinary.com/dzsptpetn/image/upload/v1669635526/charlie/profile-pictures/image_1_narxty.jpg";
const friendImage2 = "https://res.cloudinary.com/dzsptpetn/image/upload/v1669635882/charlie/profile-pictures/image_2_ajvwei.png";
const friendImage3 = "https://res.cloudinary.com/dzsptpetn/image/upload/v1669635919/charlie/profile-pictures/image_3_dguz50.png";

export const WhatsCharlieStep: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <>
            <Content
                initial={{ opacity: 0, y: "10px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: .7, type: "easeInOut" }}
            >
                <Title>What’s Charlie?</Title>
                <p>Imagine –<br />
                    you’re planning to travel to a cool destination. You’ve researched the perfect season, bought a plane ticket and maybe even booked a stay.
                </p>
                <p>
                    Now, imagine you knew a local there who would tell you all about the locals’ places – wouldn’t that be nice?!
                </p>
            </Content>

            <ProfilePictures>
                <Picture
                    style={{ zIndex: 4 }}
                    initial={{ x: "-50%" }}
                    animate={{ opacity: 1, x: "-130%" }}
                    transition={{ duration: duration, type: easingMode, delay: 0.1 }}
                >
                    <ProfilePicture
                        src={getSmallImageUrl(friendImage1, 150)}
                        title="Friend"
                    />
                </Picture>
                <Picture
                    style={{ zIndex: 3, transform: "translateX(-50%)" }}
                    initial={{}}
                    animate={{ opacity: 1 }}
                    transition={{ duration: duration, type: easingMode }}

                >
                    <ProfilePicture
                        src={getSmallImageUrl(friendImage2, 150)}
                        title="Friend"
                    />
                </Picture>
                <Picture
                    style={{ zIndex: 2 }}
                    initial={{ x: "-50%" }}
                    animate={{ opacity: 1, x: "30%" }}
                    transition={{ duration: duration, type: easingMode, delay: 0.3 }}
                >
                    <ProfilePicture
                        src={getSmallImageUrl(friendImage3, 150)}
                        title="Friend"
                    />
                </Picture>
            </ProfilePictures>
        </>
    );
}

const Title = styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: #442F0F;
`;

const Content = styled(motion.div)`
    font-weight: 400;
    color: #442F0F;
    text-align: center;

    p {
        line-height: 1.5;
        font-size: 18px;
    }

    p + p {
        margin-top: 2em;
    }
`

const ProfilePictures = styled.div`
    position: relative;
    height: 30vw;
    margin-top: 2rem;
`;

const Picture = styled(motion.div)`
    width: 30vw;
    position: absolute;
    left: 50%;
`