import { FIELDS as CITY_SEARCH_FIEDS } from '../../../../common/components/CitySearch/consts';

export const TABS = {
    DISPLAY_NAME: 'display-name',
    SELF_INTRODUCTION: "self-introduction",
    CONTACT_INFO: 'contact-info',
    PROFILE_PICTURE: 'profile-picture',
    CREDENTIALS: 'credentials',
}

export const FIELDS = {
    DISPLAY_NAME: "display-name-input",
    ...CITY_SEARCH_FIEDS,
    SELF_INTRODUCTION: "self-introduction-input",
    CONTACT_INFO: "contact-info-input",
    PROFILE_PICTURE: "profile-picture-input",
    EMAIL: 'email-input',
    PASSWORD: 'password-input',
}