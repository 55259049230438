import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { FriendModel } from '../../models/friend.model';
import { fetchFriends } from '../../api';
import { ThinHeader } from '../ThinHeader';
import { withAuthGuard } from '../../../../common/auth/guards';
import { ProfilePreviewDrawer } from '../ProfilePreviewDrawer';
import { WorldMap } from '../../../../common/components/WorldMap';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { useStats } from '../../../../common/stats';
import _ from 'lodash';
import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {

}

const defaultViewport = {
    latitude: 40,
    longitude: 0,
    zoom: 0.7,
};

const _Entry: React.FC<Props> = (props: Props) => {
    const { width, height } = useWindowSize();
    const [searchParams, setSearchParams] = useSearchParams();

    const initialViewport = parseViewport(searchParams)
    const [viewport, setViewport] = useState<Partial<ViewState>>(initialViewport);

    const [friends, setFriends] = useState<FriendModel[]>([]);

    const updateSeachParams = useCallback(_.throttle((
        nextViewport: Partial<ViewState>,
    ) => {
        const serialzied = serializeViewport(nextViewport);
        setSearchParams(serialzied, { replace: true });
    }, 500), [(setSearchParams)]);

    useEffect(() => {
        updateSeachParams(viewport);
    }, [viewport.zoom, viewport.latitude, viewport.longitude]);

    const currentFriendId = searchParams.get("user-id");
    const stats = useStats();

    useEffect(() => {
        async function init() {
            const f = await fetchFriends()
            setFriends(f);

            stats.trackEvent("Map Page Viewed");
        }

        init();
    }, []);

    const openDrawer = (friend: FriendModel) => {
        setSearchParams({ ...paramsToObject(searchParams), "user-id": friend.id });
        stats.trackEvent("Profile Preview Opened", { profileId: friend.id });
    }
    const closeDrawer = () => {
        searchParams.delete("user-id");
        setSearchParams(searchParams);
    }

    return (
        <div>
            <ThinHeader />
            <WorldMap
                viewport={viewport}
                height={height}
                width={width}
                friends={friends}
                onMove={(evt: ViewStateChangeEvent) => setViewport(evt.viewState)}
                onFriendClick={openDrawer}
            />

            {friends.map(friend => (
                <ProfilePreviewDrawer
                    key={friend.id}
                    onClose={closeDrawer}
                    isOpen={currentFriendId === friend.id}
                    friend={friend}
                />
            ))}

        </div>

    );
}

function parseViewport(searchParams: URLSearchParams): Partial<ViewState> {
    const latitude = tryParse(searchParams.get("latitude")) ?? defaultViewport.latitude;
    const longitude = tryParse(searchParams.get("longitude")) ?? defaultViewport.longitude;
    const zoom = tryParse(searchParams.get("zoom")) ?? defaultViewport.zoom;

    return {
        latitude,
        longitude,
        zoom
    }
}

export function serializeViewport(viewport: Partial<ViewState>) {
    return {
        "latitude": viewport.latitude.toString(),
        "longitude": viewport.longitude.toString(),
        "zoom": viewport.zoom.toString()
    }
}

function tryParse(val: string): number | undefined {
    try {
        const res = parseFloat(val);

        if (isNaN(res)) {
            throw Error("Could not parse number");
        }

        return res;
    } catch {
        return null;
    }
}

function paramsToObject(searchParams: URLSearchParams) {
    const entries = searchParams.entries();
    const result = {}

    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }

    return result;
}

export const Entry = withAuthGuard(_Entry);
