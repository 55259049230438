import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CityModel } from '../../models/city.model';
import { Friend } from '../Friend';
import styled from "styled-components"
import { Layout } from '../../../../common/components/Layout';
import { fetchCity } from '../../apis/dataview';
import { Loading } from '../../../../common/components/Loading';
import { useStats } from '../../../../common/stats';

interface Props {
}

export const Entry: React.FC<Props> = (props: Props) => {
    let { id } = useParams();
    const stats = useStats();
    const [city, setCity] = useState<undefined | CityModel>();

    useEffect(() => {
        async function fetchDataview() {
            if (id) {
                const data = await fetchCity(id);
                setCity(data);
            }
        }

        stats.trackEvent("City Page Viewed", { cityId: id });
        fetchDataview();
    }, [id])

    if (!city) {
        return <Loading />;
    }

    return (
        <Layout>
            <Title>{city.name}</Title>
            <Section>
                <SectionTitle>{city.friends.length} friends live here</SectionTitle>
                <FriendsItems>
                    {
                        city.friends.map(f => <Friend friend={f} key={f.id} />)
                    }
                </FriendsItems>
            </Section>
            <Section>
                <SectionTitle>{city.friendsOfFriends.length} friends of friends live here</SectionTitle>
                <FriendsItems>
                    {
                        city.friendsOfFriends.map(f => <Friend friend={f} key={f.id} />)
                    }
                </FriendsItems>
            </Section>
        </Layout>
    );
}

const Title = styled.h1`
    text-align: center;
    color: #342408;
    
    margin-top: 32px;
    margin-bottom: 48px;

    font-size: 52px;
`;

const Section = styled.div`
    & + & {
        margin-top: 5rem;
    }
`;

const SectionTitle = styled.div`
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
    color: #896C42;
`;

const FriendsItems = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`;