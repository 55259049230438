import React from 'react';
import { Section } from '../../../../common/components/Section';
import { ProfileModel } from '../../models/profile.model';
import { SectionTitle } from '../common';
import styled from "styled-components";
import { WhatsAppIcon } from '../../../../common/components/icons/WhatsAppIcon';
import { InstagramIcon } from '../../../../common/components/icons/InstagramIcon';
import { useStats } from '../../../../common/stats';

interface Props {
    profile: ProfileModel;
}


export const ContactInfo: React.FC<Props> = (props: Props) => {
    const { profile } = props;
    const hasStructuredInfo = (
        profile.structuredContactInfo.phoneNumber ||
        profile.structuredContactInfo.instagram
    );

    return hasStructuredInfo ?
        <StructuredContactInfo {...props} /> :
        <UnstructuredContactInfo {...props} />;
}

export const StructuredContactInfo: React.FC<Props> = (props: Props) => {
    const { profile } = props;
    const { phoneNumber, instagram } = profile.structuredContactInfo;
    const stats = useStats();

    const trackContactMethodClick = (method: string) => {
        stats.trackEvent("Contact Method Clicked", { method: method, profileId: profile.id });
    }

    return (
        <Section>
            <SectionTitle>Text Me</SectionTitle>
            <ContactingOptions>
                {phoneNumber &&
                    <Icon>
                        <a
                            href={toWhatsAppLink(phoneNumber)}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => trackContactMethodClick("WhatsApp")}
                        >
                            <WhatsAppIcon />
                        </a>
                    </Icon>
                }
                {instagram &&
                    <Icon>
                        <a
                            href={toInstagramLink(instagram)}
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => trackContactMethodClick("Instagram")}
                        >
                            <InstagramIcon />
                        </a>
                    </Icon>
                }
            </ContactingOptions>
        </Section>
    );
}

export const UnstructuredContactInfo: React.FC<Props> = (props: Props) => {
    const { profile } = props;

    return (
        <Section>
            <SectionTitle>Contact Info</SectionTitle>
            <Text>{profile.contactInfo}</Text>
        </Section>
    );
}

const Text = styled.p`
    text-align: center;
    margin: 0 12px;
    color: #776036;
    white-space: pre-wrap;
`;

const ContactingOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.span`
    font-size: 2rem;
    display: inline-block;
    
    & + & {
        margin-left: 1rem;
    }
`;

function toWhatsAppLink(phoneNumber: string): string {
    const sanitizedNumber = phoneNumber.replace(/[\(\)\-\+\s]/g, "");
    return `https://wa.me/${sanitizedNumber}`
}

function toInstagramLink(accountName: string): string {
    const sanitizedAcountName = accountName.replace('@', '');
    return `https://www.instagram.com/${sanitizedAcountName}`;
}