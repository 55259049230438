import React from 'react';
import styled from "styled-components";
import charlie from './charlie.png';

interface Props {
}

export const Logo: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <Container>
            <Icon src={charlie} />
            <Text>Charlie</Text>
        </Container>
    );
}

const Container = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img`
    height: 48px;
`;

const Text = styled.div`
    vertical-align: center;
    font-size: 18px;
    font-weight: 600;
`;