import React from 'react';

interface Props {

}

export const LocationIcon: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <svg
            version="1.1"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 38 38"
        >
            <g>
                <path fill="currentColor"
                    d="M36.5,14.334c0,10.693-12.766,22.754-13.309,23.262C22.902,37.865,22.535,38,22.166,38c-0.354,0-0.709-0.125-0.992-0.375
          c-0.074-0.066-0.389-0.346-0.861-0.797c2.881-2.877,12.521-13.199,12.521-22.494c0-7.281-5.463-13.298-12.502-14.203
          C20.936,0.054,21.543,0,22.166,0C30.07,0.001,36.5,6.431,36.5,14.334z M30.166,14.334c0,10.693-12.766,22.754-13.309,23.262
          C16.57,37.865,16.201,38,15.832,38c-0.354,0-0.709-0.125-0.992-0.375C14.295,37.145,1.5,25.713,1.5,14.334
          c0-7.903,6.43-14.333,14.332-14.333C23.736,0.001,30.166,6.431,30.166,14.334z M22.666,14.334c0-3.774-3.061-6.833-6.834-6.833
          C12.059,7.501,9,10.561,9,14.334s3.059,6.833,6.832,6.833C19.605,21.167,22.666,18.108,22.666,14.334z"
                />
            </g>
        </svg>
    );
}
