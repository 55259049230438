import React from 'react';
import styled from "styled-components";

interface Props {
}

export const Spinner: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <SpinnnerContainer>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </SpinnnerContainer>
    );
}


const SpinnnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 1em;
    height: 1em;
    margin: 0.1em;
    border: 0.1em solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
