import React, { useState } from 'react';
import { SuiteRunResult } from 'vest';
import { Input } from '../../../../../common/components';
import { EyeShowIcon } from '../../../../../common/components/icons/EyeShowIcon';
import { Row } from '../common';
import { FIELDS } from '../consts';
import { JoinFormData } from '../glossary';
import styled from "styled-components";
import { EyeHideIcon } from '../../../../../common/components/icons/EyeHideIcon';

interface Props {
    email: string;
    onEmailChange: (email: string) => any;
    password: string;
    onPasswordChange: (password: string) => any;
    validate: (formData: Partial<JoinFormData>, field: string) => any;
    validationResult?: SuiteRunResult;
}

export const CredentialsStep: React.FC<Props> = (props: Props) => {
    const {
        email,
        password,
        validationResult,
    } = props;
    const [passwordIsHidden, setPasswordIsHidden] = useState(true);

    const validateEmail = (email: string) => {
        props.validate({ email }, FIELDS.EMAIL);
    }

    const onEmailChange = (email: string) => {
        validateEmail(email);
        props.onEmailChange(email);
    }

    const validatePassword = (password: string) => {
        props.validate({ password }, FIELDS.PASSWORD);
    }

    const onPasswordChange = (password: string) => {
        validatePassword(password);
        props.onPasswordChange(password);
    }

    const passwordIcon = passwordIsHidden ? (
        <InputIcon aria-label="Show password" onClick={() => setPasswordIsHidden(false)}>
            <EyeShowIcon />
        </InputIcon>
    ) : (
        <InputIcon aria-label="Hide password" onClick={() => setPasswordIsHidden(true)}>
            <EyeHideIcon />
        </InputIcon>
    )

    return (
        <div>
            <Row>
                <Title>Create your account</Title>
            </Row>
            <Row>
                <label htmlFor={FIELDS.EMAIL}>Email</label>
                <Input id={FIELDS.EMAIL}
                    value={email}
                    onChange={onEmailChange}
                    onBlur={() => validateEmail(email)}
                    errors={validationResult?.getErrors(FIELDS.EMAIL)}
                />
            </Row>
            <Row>
                <label htmlFor={FIELDS.PASSWORD}>Password</label>
                <Input id={FIELDS.PASSWORD}
                    type={passwordIsHidden ? "password" : "text"}
                    value={password}
                    onChange={onPasswordChange}
                    onBlur={() => validatePassword(password)}
                    errors={validationResult?.getErrors(FIELDS.PASSWORD)}
                    icon={<PasswordIconContainer>{passwordIcon}</PasswordIconContainer>}
                />
            </Row>
        </div>
    );
}

const PasswordIconContainer = styled.div`
    position: relative;
    height: 100%;
`;

const InputIcon = styled.button`
    background: none;
    border: none;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    color: #593a07;
    position: absolute;
    right: 0;
    top: 50%;
    height: 24px;
    width: 24px;
    transform: translateY(-50%);
`;

const Title = styled.h2`
    color: #442F0F;
`;
