import React from 'react';
import { SuiteRunResult } from 'vest';
import { TextArea } from '../../../../../common/components/TextArea';
import { Hint, Row } from '../common';
import { FIELDS } from '../consts';
import { JoinFormData } from '../glossary';

interface Props {
    selfIntroduction: string;
    onSelfIntroductionChange: (value: string) => any;

    validate: (formData: Partial<JoinFormData>, field: string) => any;
    validationResult?: SuiteRunResult;
}

export const SelfIntroductionStep: React.FC<Props> = (props: Props) => {
    const {
        selfIntroduction,
        validationResult
    } = props;

    const validateSelfIntroduction = (value: string) => {
        const formData: Partial<JoinFormData> = { selfIntroduction: value };
        props.validate(formData, FIELDS.SELF_INTRODUCTION);
    }

    const onSelfIntroductionChange = (value: string) => {
        validateSelfIntroduction(value);
        props.onSelfIntroductionChange(value);
    }

    return (
        <Row>
            <label htmlFor={FIELDS.SELF_INTRODUCTION}>Tell us about yourself</label>
            <TextArea id={FIELDS.SELF_INTRODUCTION}
                value={selfIntroduction}
                onChange={onSelfIntroductionChange}
                rows={5}
                onBlur={() => validateSelfIntroduction(selfIntroduction)}
                errors={validationResult?.getErrors(FIELDS.SELF_INTRODUCTION)}
            />
            <Hint>Don't worry, just one or two sentences should be enough!</Hint>
        </Row>
    );
}
