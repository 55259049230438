import React from 'react';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';
import colors from '../../styles/colors.module.scss'
import { Validations } from '../Validations';
const { grey100, grey300, grey400, grey700, grey900 } = colors;

interface FormControlProps {
    errors?: string[];
}

type SelectProps = FormControlProps & React.ComponentProps<typeof ReactSelect>;
type AsyncSelectProps = FormControlProps & React.ComponentProps<typeof ReactAsyncSelect>;

const colorStyles = {
    container: (styles, state) => ({
        ...styles,
        transition: "all .5s",
    }),
    control: (styles, state) => ({
        ...styles,
        border: `2px solid ${getBorderColor(state)}`,
        borderRadius: "6px",
        background: state.isDisabled ? grey100 : "white",
        boxShadow: state.isFocused ? `0 3px 5px 0 ${grey400}, 0 7px 30px 7px ${grey300}` : "",
        transition: "all .5s",
    }),
    valueContainer: (styles, state) => ({
        ...styles,
        padding: 0
    }),
    input: (styles, state) => ({
        ...styles,
        padding: "14px",
        fontSize: "18px"
    }),
    placeholder: (styles, state) => ({
        ...styles,
        color: "#c1af95",
        padding: "14px",
        fontSize: "18px"
    }),
    singleValue: (styles, state) => ({
        ...styles,
        padding: "14px",
        fontSize: "18px",
        color: grey700
    }),
    indicatorsContainer: (styles, state) => ({
        ...styles,
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        padding: "14px 16px",
    }),
    clearIndicator: (styles, state) => ({
        ...styles,
        padding: "14px 16px",
    }),
    indicatorSeparator: (styles, state) => ({
        ...styles,
    })
}

export const Select: React.FC<SelectProps> = (props: SelectProps) => {
    return (
        <>
            <ReactSelect {...props} styles={colorStyles} />
            <Validations errors={props.errors} />
        </>
    );
}

export const AsyncSelect: React.FC<AsyncSelectProps> = (props: AsyncSelectProps) => {
    return (
       <>
            <ReactAsyncSelect {...props} styles={colorStyles} />
            <Validations errors={props.errors} />
       </>
    );
}

function getBorderColor(state) {
    if (state.isDisabled) {
        return grey300
    }
    if (state.isFocused) {
        return grey900
    }

    return grey400
}


