import React, { useState } from 'react';
import { Step } from './glossary';
import styled from "styled-components";
import { Button } from '../Button';
import { GhostButton } from '../GhostButton';
import _ from 'lodash';

interface Props {
    steps: Step[];
    onComplete: () => any;
}

export const Stepper: React.FC<Props> = (props: Props) => {
    const { steps: allSteps, onComplete } = props;
    const steps = filter(allSteps);

    const [currentIndex, setCurrentIndex] = useState(0);
    const { component, submitButtonText, beforeSubmit } = steps[currentIndex];

    const showGhostButton = currentIndex !== 0;

    const onSubmit = () => {
        if (_.isFunction(beforeSubmit) && !beforeSubmit()) {
            return;
        }

        const isLastStep = currentIndex === steps.length - 1
        if (isLastStep) {
            onComplete();
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    }

    return (
        <div>
            {component}
            <Actions>
                {
                    showGhostButton ? (
                        <GhostButton onClick={() => setCurrentIndex(currentIndex - 1)}>Previous</GhostButton>
                    ) : (
                        <Spacer />
                    )
                }
                <Button onClick={onSubmit}>{submitButtonText}</Button>
            </Actions>
        </div>
    );
}

function filter(steps: Step[]): Step[] {
    return steps.filter((step) => !step.skip);
}

const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
`;

const Spacer = styled.div`
    flex: 1;
`