import React from 'react';

interface Props {
    label?: string;
}

export const EyeShowIcon: React.FC<Props> = (props: Props) => {
    const { label } = props;

    return (
        <svg
            x="0px"
            y="0px"
            viewBox="0 0 279.267 279.267"
            width="1em"
            height="1em"
            aria-label={label}
        >
            <path fill="currentColor" d="M236.655,93.913c-32.237-27.332-65.786-41.779-97.021-41.779S74.849,66.581,42.612,93.913
       c-23.995,20.345-38.77,40.454-39.387,41.301L0,139.633l3.225,4.42c0.617,0.847,15.393,20.956,39.387,41.301
       c32.237,27.332,65.786,41.779,97.021,41.779s64.785-14.447,97.021-41.779c23.995-20.345,38.77-40.454,39.387-41.301l3.225-4.42
       l-3.225-4.42C275.425,134.367,260.649,114.257,236.655,93.913z M139.633,212.133c-57.761,0-107.552-56.146-120.83-72.504
       c13.262-16.369,62.967-72.496,120.83-72.496c57.761,0,107.552,56.146,120.83,72.504
       C247.201,156.006,197.496,212.133,139.633,212.133z M139.633,81.785c-31.898,0-57.85,25.951-57.85,57.849
       s25.951,57.849,57.85,57.849s57.85-25.951,57.85-57.849S171.532,81.785,139.633,81.785z M139.633,182.482
       c-23.627,0-42.85-19.222-42.85-42.849s19.222-42.849,42.85-42.849s42.85,19.222,42.85,42.849S163.261,182.482,139.633,182.482z
        M163.79,130.6c1.548,3.37,2.417,7.119,2.417,11.072c0,14.676-11.898,26.575-26.575,26.575c-14.677,0-26.575-11.899-26.575-26.575
       c0-3.953,0.87-7.702,2.418-11.072c4.2,9.145,13.434,15.502,24.157,15.502S159.59,139.746,163.79,130.6z"/>
        </svg>
    );
}
