import React, { useState } from 'react';
import { Button } from '../../../../../common/components';
import { Hint, Row, TabTitle } from '../common';
import { InstagramModal } from './ContactInfo/InstagramModal';
import { PhoneNumberModal } from './ContactInfo/PhoneNumberModal';
import styled from "styled-components";
import { SuiteRunResult } from 'vest';
import { FIELDS } from '../consts';
import { Validations } from '../../../../../common/components/Validations';
import { JoinFormData } from '../glossary';

interface Props {
    phoneNumber: string;
    onPhoneNumberChange: (value: string) => any;

    instagram: string;
    onInstagramChange: (value: string) => any;

    validate: (formData: Partial<JoinFormData>, field: string) => any;
    validationResult?: SuiteRunResult;
}

interface ContactInfo {
    phoneNumber?: string;
    instagram?: string;
}

export const ContactInfoStep: React.FC<Props> = (props: Props) => {
    const {
        phoneNumber,
        onPhoneNumberChange,
        instagram,
        onInstagramChange,
        validationResult
    } = props;
    const [showPhoneNumberModal, setShowPhoneNumberModal] = useState(false);
    const [showInstagramModal, setShowInstagramModal] = useState(false);

    const openPhoneNumberModal = () => setShowPhoneNumberModal(true);
    const closePhoneNumberModal = () => setShowPhoneNumberModal(false);

    const openInstagramModal = () => setShowInstagramModal(true);
    const closeInstagramModal = () => setShowInstagramModal(false);

    const validate = (info: Partial<ContactInfo>) => {
        const data = { phoneNumber, instagram, ...info };
        props.validate(data, FIELDS.CONTACT_INFO);
    }

    const onPhoneNumberSaved = (value: string) => {
        validate({ phoneNumber: value });
        onPhoneNumberChange(value);
        closePhoneNumberModal();
    }

    const onInstagramSaved = (value: string) => {
        validate({ instagram: value });
        onInstagramChange(value);
        closeInstagramModal();
    }

    return (
        <Row>
            <TabTitle>Contact info</TabTitle>
            <Hint>One method should be enough, but adding more would make it easier for others to contact you.</Hint>

            <Cards>
                <OptionButton
                    onClick={openPhoneNumberModal}
                    selected={phoneNumber !== ""}
                >
                    Phone Number
                </OptionButton>

                <OptionButton
                    onClick={openInstagramModal}
                    selected={instagram !== ""}
                >
                    Instagram
                </OptionButton>
            </Cards>

            <Validations errors={validationResult?.getErrors(FIELDS.CONTACT_INFO)} />



            <PhoneNumberModal
                isOpen={showPhoneNumberModal}
                phoneNumber={phoneNumber}
                onSubmit={onPhoneNumberSaved}
                onCancel={closePhoneNumberModal}
            />

            <InstagramModal
                isOpen={showInstagramModal}
                instagram={instagram}
                onSubmit={onInstagramSaved}
                onCancel={closeInstagramModal}
            />

        </Row>
    );
}

type ButtonProps = React.ComponentProps<typeof Button>;
type OptionButtonProps = ButtonProps & { selected: boolean }

const OptionButton: React.FC<OptionButtonProps> = styled(Button)`
    color: #794d0b;
    box-shadow: 0 2px 3px 0 #ae8f61, 0 7px 30px 7px #f4d29e;
    border-radius: 8px;
    height: 5em;
    width: 40vw;
    margin: 0.5rem 0;
    background: #ffe8c5;

    border: ${(props: OptionButtonProps) => props.selected ? "3px solid #fff" : ""};
`;

const Cards = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`