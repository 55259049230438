import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../../common/components/Layout';
import { fetchProfileData } from '../../apis/dataview';
import { ProfileModel } from '../../models/profile.model';
import styled from "styled-components";
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { withAuthGuard } from '../../../../common/auth/guards';
import { Link } from 'react-router-dom';
import { LocationIcon } from '../../../../common/components/icons/LocationIcon';
import { MutualFriendsSection } from '../MutualFriendsSection';
import { Section } from '../../../../common/components/Section';
import { getSmallImageUrl } from '../../../../common/image';
import { useStats } from '../../../../common/stats';
import { ContactInfo } from '../ContactInfo';
import { FriendRequest } from '../FriendRequest';

interface Props {
}

const _Entry: React.FC<Props> = (props: Props) => {
    let { id } = useParams();
    const stats = useStats();
    const [profile, setProfile] = useState<ProfileModel | undefined>();

    useEffect(() => {
        async function fetchData() {
            const p = await fetchProfileData(id);
            setProfile(p);
        }

        stats.trackEvent("Profile Page Viewed", { profileId: id });
        fetchData();
    }, [id])

    if (!profile) {
        return null;
    }

    return (
        <Layout>
            <Section>
                <ProfilePictureContainer>
                    <ProfilePicture src={getSmallImageUrl(profile.image, 300)} title={profile.displayName} />
                </ProfilePictureContainer>
                <Title>{profile.displayName}</Title>

                <Location>
                    <Link to={`/city/${profile.location.city.id}?source=profile-page&source-element=user-location`}>
                        <LocationIconContainer>
                            <LocationIcon />
                        </LocationIconContainer>
                        <LocationLinkText>
                            {profile.location.city.name}, {profile.location.country.name}
                        </LocationLinkText>
                    </Link>
                </Location>

                <FriendRequest profile={profile}/>

                <SelfIntroduction>{profile.selfIntroduction}</SelfIntroduction>
            </Section>

            <ContactInfo profile={profile} />

            <MutualFriendsSection mutualFriends={profile.mutualFriends} />
        </Layout>
    );
}

export const Entry = withAuthGuard(_Entry);

const Title = styled.h1`
    text-align: center;
    color: #342408;
    
    margin-top: 16px;
    margin-bottom: 0;

    font-size: 52px;
`;

const ProfilePictureContainer = styled.div`
    width: 70%;
    margin: 0 auto;
    margin-top: 24px;
`;

const SelfIntroduction = styled.p`
    text-align: center;
    margin: 0 12px;
    font-size: 18px;
    color: #776036;
    white-space: pre-wrap;
`;

const Location = styled.div`
    margin-bottom: 2rem;
    text-align: center;

    a {
        text-decoration: none;
        font-size: .8rem;
        font-weight: 600;
        color: #6a522b;
    }
`;

const LocationIconContainer = styled.span`
    vertical-align: middle;
    margin-right: .35em;
    color: #8a6d3c;
    margin-top: 1px;
    display: inline-block;
`

const LocationLinkText = styled.span`
    vertical-align: middle;
`
