import React from 'react';
import flags from './flags';
import styled from "styled-components";

interface Props {
    countryCode: string;
    title?: string;
    size: number;
}

export const FlagIcon: React.FC<Props> = (props: Props) => {
    const { countryCode, title, size } = props;
    const usFlag = flags[countryCode.toLowerCase()];

    return (
        <Image src={usFlag} height={size} alt={title} title={title} />
    );
}

const Image = styled.img`
    border: 1px solid #e1c59a;
    border-radius: 100%;
`
