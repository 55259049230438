import React, { useEffect, useState, createContext, useContext } from 'react';
import { wait } from '../../wait';
import { Loading } from '../Loading';
import { fetchContext } from './api';
import { ContextModel } from './model/context.model';

interface Props {
    children?: React.ReactNode;
}

const Context = createContext(null)

export const useAuthContext = (): undefined | ContextModel => useContext(Context);

const DEFAULT_CONTEXT: ContextModel = {
    userId: null
}

export const AuthContext: React.FC<Props> = (props: Props) => {
    const { children } = props;
    const [accessToken, setAccessToken] = useState();
    const [loading, setLoading] = useState(true);
    const [context, setContext] = useState<undefined | ContextModel>();

    useEffect(() => {
        async function init() {
            setLoading(true);

            const getContext = async () => {
                return (await fetchContext()) ?? DEFAULT_CONTEXT;
            }

            const [ctx] = await Promise.all([getContext(), wait(500)]);
            setContext(ctx);
            setLoading(false);
        }

        init();
    }, [accessToken]);

    useEffect(() => {
        const handleEvent = ({ key, newValue }) => {
            if (key === 'access_token') {
                setAccessToken(newValue);
            }
        };

        window.addEventListener('storage', handleEvent);
    }, [])

    if (loading) {
        return <Loading />;
    }

    return (
        <Context.Provider value={context}>
            {children}
        </Context.Provider>
    );
}
