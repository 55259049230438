import React from 'react';
import { InvitationModel } from '../../model/invitation.model';
import styled from 'styled-components';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { Button } from '../../../../common/components';
import { getSmallImageUrl } from '../../../../common/image';
import { useAuthContext } from '../../../../common/components/AuthContext';

interface Props {
    invitation: InvitationModel;
    onClick?: () => any;
}

export const InvitationPreview: React.FC<Props> = (props: Props) => {
    const { invitation, onClick } = props;
    const { userId } = useAuthContext();
    const buttonText = (userId) ? "Accept" : "Continue"
    const showCta = invitation.isActive;

    return (
        <>
            <Section>
                <ProfilePictureContainer>
                    <ProfilePicture src={getSmallImageUrl(invitation.invitingUser.image, 200)} title={invitation.invitingUser.displayName} />
                </ProfilePictureContainer>
                <Title>{invitation.invitingUser.displayName} is inviting you to become their friend!</Title>
            </Section>

            {showCta ? (
                <Center>
                    <Button onClick={onClick}>{buttonText}</Button>
                </Center>
            ) : (
                <Message>
                    Sorry! This invitation is no longer valid.<br />
                    Please ask {invitation.invitingUser.displayName} to send you a new one.
                </Message>
            )}
        </>
    );
}


const Section = styled.div`
    margin: 0 12px;
    
    & + & {
        margin-top: 5rem;
    }
`;

const ProfilePictureContainer = styled.div`
    width: 70%;
    margin: 0 auto;
    margin-top: 24px;
`;

const Title = styled.h1`
    text-align: center;
    color: #342408;
    
    margin-top: 16px;
    margin-bottom: 48px;

    font-size: 42px;
`;

const Center = styled.div`
    text-align: center;
`;

const Message = styled.div`
    text-align: center;
    color: #4F3A19;
    background: #F6D6A5;
    padding: 16px;
    border-radius: 8px;
    margin: 0 16px;
`;