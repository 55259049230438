import classNames from 'classnames';
import React from 'react';
import { Validations } from '../Validations';
import classes from './index.module.scss';

interface Props {
    value?: string;
    type?: string;
    onChange?: (value: string) => any;
    id?: string;
    rows?: number
    onBlur?: () => any;
    errors?: string[];
}

export const TextArea: React.FC<Props> = (props: Props) => {
    const {
        value,
        onChange,
        errors,
        ...otherProps
    } = props;
    
    const hasErrors = errors && errors.length > 0;
    const clazz = classNames(classes.textarea, {[classes.error]: hasErrors})

    return (
        <>
            <textarea
                className={clazz}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                {...otherProps}
            />
            <Validations errors={errors} />
        </>
    );
}
