import React from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion"

interface Props {

}

export const BecomePartOfTheCommunityStep: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <Content
            initial={{ opacity: 0, y: "10px" }}
            animate={{ opacity: 1, y: "0" }}
            transition={{ duration: .7, type: "easeInOut" }}
        >
            <h2>Become part of Charlie’s community!</h2>
            <p>Create your own profile in just 2 minutes</p>
        </Content>
    );
}

const Content = styled(motion.div)`
    font-weight: 400;
    color: #442F0F;
    text-align: center;

    p {
        line-height: 1.5;
        font-size: 18px;
    }

    p + p {
        margin-top: 2em;
    }
`