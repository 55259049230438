import { NotificationsPreviewModel } from "../../../../common/components/Header/Menu/models/notifications-preview.model";

export interface GetNotificationsPreviewResponse {
    count: number;
}

export function toNotificationsPreviewModel(
    contract: GetNotificationsPreviewResponse
): NotificationsPreviewModel {
    return {
        count: contract.count
    }
}