import { FriendRequestModel } from "../../../models/friend-request.model";

export interface GetFriendRequestsResponse {
    requests: FriendRequestDto[];
}

export interface FriendRequestDto {
    id: string;
}

export function toFriendRequestModel({ requests }: GetFriendRequestsResponse): FriendRequestModel | null {
    if (requests.length === 0) {
        return null;
    }

    const request = requests[0];

    return {
        id: request.id
    }
}