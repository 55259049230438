import { CityModel } from "../../model/geo/city.model";

export interface CityDto {
    id: number;
    name: string;
    countryCode: string;
}

export interface FindCityResponse {
    cities: CityDto[];
}

export function toCityModel(contract: CityDto): CityModel {
    return {
        id: contract.id,
        countryCode: contract.countryCode,
        name: contract.name
    }
}