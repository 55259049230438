import React from 'react';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { LoggedInHomePage } from '../../../logged-in-home-page';
import { LoggedOutHomePage } from '../../../logged-out-home-page';

interface Props {

}

export const Entry: React.FC<Props> = (props: Props) => {
    const {userId} = useAuthContext();

    if (userId) {
        return <LoggedInHomePage />
    }
    
    return (
        <LoggedOutHomePage />
    );
}
