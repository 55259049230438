import React, { useCallback, useEffect, useState } from 'react';
import { fetchCountries, findCityByName } from '../../../pages/join/api';
import { CountryModel } from '../../../pages/join/model/geo/country.model';
import { CityModel } from '../../../pages/join/model/geo/city.model';
import { CitySearchFormData, Option } from './glossary';
import styled from "styled-components";
import _ from 'lodash';
import { Select, AsyncSelect } from '../Select';
import { FIELDS } from './consts';
import { SuiteRunResult } from 'vest';
import { wait } from '../../wait';

interface Props {
    selectedCountryOption?: Option;
    selectedCityOption?: Option;
    onSelectedCountryOption?: (opeion: Option) => any;
    onSelectedCityOption?: (opeion: Option) => any;

    validate?: (data: Partial<CitySearchFormData>, fieldName?: string) => any;
    validationResult?: SuiteRunResult;
}

export const CitySearch: React.FC<Props> = (props: Props) => {
    const {
        selectedCountryOption,
        selectedCityOption,
        onSelectedCountryOption,
        onSelectedCityOption,
        validate,
        validationResult
    } = props;
    const [countryOptions, setCoutnryOptions] = useState<Option[]>([]);

    useEffect(() => {
        async function initCountryOptions() {
            const data = await fetchCountries();
            const options = data.map(toCountryOption);
            setCoutnryOptions(options);
        }

        initCountryOptions();
    }, []);

    const onCountrySelectChange = (value?: Option) => {
        onSelectedCityOption(null);
        onSelectedCountryOption(value);
        validateCountry(value);
    }

    const onCitySelectChange = async (value: any) => {
        onSelectedCityOption(value);
        validateCity(value);
    }

    const loadCityOptions = useCallback(_.throttle((
        inputValue: string,
        callback: (options: Option[]) => void
    ) => {
        if (!inputValue || inputValue.length < 1) {
            callback([]);
            return;
        }

        findCityByName({
            countryCode: selectedCountryOption.value,
            query: inputValue
        }).then(cities => callback(cities.map(toCityOption)));
    }, 500), [(selectedCountryOption?.value)]);

    const validateCountry = (value?: Option) => {
        if (!validate) {
            return;
        }

        const data: Partial<CitySearchFormData> = {
            country: value,
            city: selectedCityOption
        }

        validate(data, FIELDS.COUNTRY);
    }
    const validateCity = (value?: Option) => {
        if (!validate) {
            return;
        }

        const data: Partial<CitySearchFormData> = {
            country: selectedCountryOption,
            city: value
        }

        validate(data, FIELDS.CITY);
    }

    return (
        <div>
            <Row>
                <label id={FIELDS.COUNTRY}>Country</label>
                <Select aria-labelledby={FIELDS.COUNTRY}
                    options={countryOptions}
                    value={selectedCountryOption}
                    onChange={onCountrySelectChange}
                    errors={validationResult?.getErrors(FIELDS.COUNTRY)}
                />
            </Row>
            <Row>
                <label id="city-label">City</label>
                <AsyncSelect aria-labelledby='city-label'
                    isDisabled={!selectedCountryOption}
                    loadOptions={loadCityOptions}
                    value={selectedCityOption}
                    onChange={onCitySelectChange}
                    isClearable
                    isSearchable
                    errors={validationResult?.getErrors(FIELDS.CITY)}
                />
            </Row>
        </div>
    );
}


function toCountryOption(country: CountryModel): Option {
    return {
        key: country.countryCode,
        label: country.name,
        value: country.countryCode
    }
}

function toCityOption(city: CityModel): Option {
    return {
        key: city.id.toString(),
        label: city.name,
        value: city.id.toString()
    }
}

const Row = styled.div`
    label {
        margin-bottom: 8px;
        display: inline-block;
        color: #513a10;
    }

    & + & {
        margin-top: 24px;
    }
`