import React, { useEffect, useRef, useState } from 'react';
import { ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { useNavigate } from 'react-router-dom';
import { WorldMap } from '../../../../common/components/WorldMap';
import { FriendModel } from '../../../../common/components/WorldMap/model/friend.model';
import { useWindowSize } from '../../../../common/hooks/useWindowSize';
import { fetchFriends } from '../../../map/api';
import { serializeViewport } from '../../../map/components/Entry';
import qs from 'qs';
import styled from "styled-components";

interface Props {
}

export const MapSection: React.FC<Props> = (props: Props) => {
    const containerRef = useRef<HTMLDivElement>();
    const navigate = useNavigate();
    const { width, height: flakyHeight } = useWindowSize();
    const [friends, setFriends] = useState<FriendModel[]>([]);
    const [viewport, setViewport] = useState<Partial<ViewState>>({
        latitude: 40,
        longitude: 0,
        zoom: 0.7,
    });
    const height = containerRef.current?.clientHeight ?? flakyHeight;

    useEffect(() => {
        async function init() {
            const f = await fetchFriends()
            setFriends(f);
        }

        init();
    }, []);

    const onFriendClick = (friend: FriendModel) => {
        const serializedViewport = serializeViewport(viewport);
        const searchParams = qs.stringify({ ...serializedViewport, 'user-id': friend.id })
        
        navigate(`/map?${searchParams}`)
    }

    return (
        <Contaienr ref={containerRef}>
            <WorldMap
                viewport={viewport}
                width={width}
                height={height}
                friends={friends}
                onMove={(evt: ViewStateChangeEvent) => setViewport(evt.viewState)}
                onFriendClick={onFriendClick}
            />
        </Contaienr>
    );
}

const Contaienr = styled.div`
    height: 100%;
    width: 100%;
`;