import React from 'react';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { FriendModel } from '../../models/friend.model';
import styled from "styled-components";
import { getSmallImageUrl } from '../../../../common/image';
import { Link } from 'react-router-dom';

interface Props {
    friend: FriendModel;
}

export const Friend: React.FC<Props> = (props: Props) => {
    const { friend } = props;

    return (
        <ContainerLink to={`/profile/${friend.id}?source=logged-in-home-page--friends-gallery`}>
            <ProfilePicture src={getSmallImageUrl(friend.image, 150)} title={friend.displayName} />
            <Text>{friend.displayName}</Text>
        </ContainerLink>
    );
}

const ContainerLink = styled(Link)`
    display: flex;
    flex-direction: column;
    width: 150px;
    margin: 12px;
    
    text-decoration: none;
`

const Text = styled.div`
    margin-top: .5rem;
    text-align: center;
    font-weight: 600;
    color: #503812;
`
