import axios from "axios";
import { authorizationHeaders } from "../../../../common/apis/auth";
import { Urls } from "../../../../common/apis/urls";
import { FriendRequestCreationResult } from "../../models/friend-request-creation-result.model";
import { FriendRequestModel } from "../../models/friend-request.model";
import { ProfileModel } from "../../models/profile.model";
import { CreateFriendRequestRequest, CreateFriendRequestResponse, toFriendRequestCreationResult } from "./contracts/create-friend-request.contract";
import { DataViewResult, toProfileModel } from "./contracts/dataview.result";
import { GetFriendRequestsResponse, toFriendRequestModel } from "./contracts/get-friend-requests.response";

export async function fetchProfileData(userId: string): Promise<ProfileModel> {
    const { data } = await axios.get<DataViewResult>(Urls.getProfile(userId), authorizationHeaders());

    return toProfileModel(data);
}

export async function createFriendRequest(recipientUserId: string): Promise<FriendRequestCreationResult> {
    const body: CreateFriendRequestRequest = { recipientUserId };
    const { data } = await axios.post<CreateFriendRequestResponse>(Urls.createFriendRequest(), body, authorizationHeaders())
    
    return toFriendRequestCreationResult(data);
}

export async function getFriendRequest(recipientUserId: string): Promise<FriendRequestModel | null> {
    try {
        const { data } = await axios.get<GetFriendRequestsResponse>(Urls.getFriendRequestsByRecipient(recipientUserId), authorizationHeaders());
        return toFriendRequestModel(data);
    } catch (error) {
        if (error.response?.status === 404) {
            return null;
        }

        throw error;
    }
}
