import React from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';
import styled from 'styled-components';
interface Props {
    children: React.ReactNode;
    showHeader?: boolean;
    showFooter?: boolean;
    fullPage?: boolean
}

export const Layout: React.FC<Props> = (props: Props) => {
    const { children, showHeader, showFooter, fullPage } = props;

    const PageWrapper = fullPage ? FlexWrapper : NonFlexWrapper;

    return (
        <React.Fragment>
            <PageWrapper>
                {showHeader !== false && <Header />}
                <ContentWrapper>{children}</ContentWrapper>
                {showFooter !== false && <Footer />}
            </PageWrapper>
        </React.Fragment>
    );
}

const FlexWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

const NonFlexWrapper = styled.div``;

const ContentWrapper = styled.div`
    flex: 1;
`;
