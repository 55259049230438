import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { useAuthContext } from '../AuthContext';
import { ProfilePicture } from '../ProfilePicture';
import { Logo } from '../Logo';
import { Menu } from './Menu';
import { getSmallImageUrl } from '../../image';
import { UnstyledLink } from '../UnstyledLink';

interface Props {
}

export const Header: React.FC<Props> = (props: Props) => {
    const context = useAuthContext();

    if (context?.userId) {
        return <LoggedInHeader {...props} />
    }

    return <LoggedOutHeader {...props} />
}

const LoggedOutHeader: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <Container>
            <UnstyledLink to="/">
                <Logo />
            </UnstyledLink>
        </Container>
    );
}

const LoggedInHeader: React.FC<Props> = (props: Props) => {
    const { } = props;
    const { image, displayName } = useAuthContext();

    return (
        <Container>

            <UnstyledLink to="/">
                <Logo />
            </UnstyledLink>

            <MenuContainer>
                <Menu>
                    <PictureContainer>
                        <ProfilePicture src={getSmallImageUrl(image, 50)} title={displayName} />
                    </PictureContainer>
                </Menu>
            </MenuContainer>

        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    position: relative;
`;

const MenuContainer = styled.div`
    width: 42px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

const PictureContainer = styled.div`
    width: 42px;
`;
