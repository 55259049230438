import React from 'react';
import classNames from 'classnames';
import classes from './index.module.scss';
import { Link } from "react-router-dom";

interface Props {
    children?: React.ReactNode;
    onClick?: () => any;
    className?: string;
    type?: "button" | "submit";
    href?: string;
    disabled?: boolean;
    style?: object;
    size?: ButtonSize;
}

export enum ButtonSize {
    SMALL,
    MEDIUM
}

export const Button: React.FC<Props> = (props: Props) => {
    const {
        children,
        onClick,
        className: clazzName,
        type,
        href,
        disabled,
        style,
        size
    } = props;

    const className = classNames(classes.button, clazzName, { [classes.small]: size === ButtonSize.SMALL });

    if (href) {
        return (
            <Link
                className={className}
                to={href}
            >
                {children}
            </Link>
        )
    }

    return (
        <button
            className={className}
            onClick={onClick}
            type={type ?? "button"}
            disabled={disabled}
            style={style}
        >
            {children}
        </button>
    );
}
