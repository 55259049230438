import React, { useEffect } from 'react';
import { Button, Input, Modal } from '../../../../../../common/components';
import styled from "styled-components";
import { GhostButton } from '../../../../../../common/components/GhostButton';
import { Hint, Row } from '../../common';

interface Props {
    isOpen: boolean;
    phoneNumber: string;
    onSubmit: (value: string) => any;
    onCancel: () => any;
}

export const PhoneNumberModal: React.FC<Props> = (props: Props) => {
    const { isOpen, phoneNumber } = props;
    const [value, setValue] = React.useState(phoneNumber);

    const onSubmit = () => {
        props.onSubmit(value);
    }

    const onCancel = () => {
        setValue(phoneNumber);
        props.onCancel();
    }

    useEffect(() => {
        setValue(phoneNumber);
    }, [phoneNumber])

    return (
        <Modal isOpen={isOpen}>
            <Row>
                <label htmlFor="phone-number-input">Your phone number</label>
                <Input
                    id="phone-number-input"
                    value={value}
                    onChange={setValue}
                    placeholder="Example: 269-290-3367"
                />
                <Hint>Please enter your international phone number.</Hint>
            </Row>
            <Actions>
                <GhostButton onClick={onCancel}>Cancel</GhostButton>
                <Button onClick={onSubmit}>Save</Button>
            </Actions>
        </Modal>
    );
}

const Actions = styled.div`
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
`;
