import React from 'react';
import styled from "styled-components";
import { Logo } from '../../../../common/components/Logo';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { Menu } from '../../../../common/components/Header/Menu';
import { getSmallImageUrl } from '../../../../common/image';
import { UnstyledLink } from '../../../../common/components/UnstyledLink';

interface Props {
}

export const ThinHeader: React.FC<Props> = (props: Props) => {
    const { } = props;
    const { image, displayName } = useAuthContext();

    return (
        <>
            <LogoWrapper>
                <UnstyledLink to="/">
                    <Logo />
                </UnstyledLink>
            </LogoWrapper>

            <MenuContainer>
                <Menu>
                    <PictureContainer>
                        <ProfilePicture src={getSmallImageUrl(image, 50)} title={displayName} />
                    </PictureContainer>
                </Menu>
            </MenuContainer>
        </>
    );
}


const LogoWrapper = styled.div`
    position: absolute;
    top: 16px;
    z-index: 3;
    left: 16px;
`;

const MenuContainer = styled.div`
    width: 42px;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 3;
`;

const PictureContainer = styled.div`
    width: 42px;
`;
