import React from 'react';
import styled from "styled-components";
import colors from '../../styles/colors.module.scss';
const { error900} = colors;

interface Props {
    errors?: string[];
}

export const Validations: React.FC<Props> = (props: Props) => {
    const { errors } = props;

    if (!errors) {
        return null;
    }

    return (
        <div>
            {errors.map((s, idx) => <Error key={idx}>{s}</Error>)}
        </div>
    );
}

const Error = styled.div`
    padding: 8px 16px;
    color: ${error900};
    font-size: .9rem;
`;