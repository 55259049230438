import React from 'react';
import { Layout } from '../Layout';
import Lottie from 'react-lottie';
import animationData from '../../lotties/lodaing.json';
import styled from 'styled-components';

interface Props {

}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export const Loading: React.FC<Props> = (props: Props) => {
    const { } = props;
    
    return (
        <Layout fullPage={true}>
             <Container title="Loading">
                 <Lottie
                    style={{ height: "80vw" }}
                    options={defaultOptions}
                />
             </Container>
        </Layout>
    );
}

const Container = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    /* margin: 0 10%; */
`;
