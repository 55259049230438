import React from 'react';
import styled from "styled-components";
import { ViewState } from 'react-map-gl';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { getSmallImageUrl } from '../../../../common/image';
import { FriendModel } from '../model/friend.model';


const PIN_MIN_SIZE = 10;
const PIN_MAX_SIZE = 40;

interface Props {
    friend: FriendModel;
    viewport: Partial<ViewState>;
    onClick: () => void;
}

export const Anchor: React.FC<Props> = (props: Props) => {
    const { friend, viewport, onClick } = props;
    const containerSize = getPinSize(viewport.zoom);

    return (
        <>
            <Container
                height={containerSize}
                width={containerSize}
                onClick={onClick}>
                <StyledPicture src={getSmallImageUrl(friend.image, 50)} title={friend.displayName} />
            </Container>
        </>
    );
}


function getPinSize(zoom) {
    return PIN_MIN_SIZE + (PIN_MAX_SIZE - PIN_MIN_SIZE) / (1 + Math.pow(Math.E, -1 * zoom));
}

const Container: React.FC<any> = styled.div`
    position: relative;
    height: ${(props: any) => props.height}px;
    width: ${(props: any) => props.width}px;
    cursor: pointer;
`;

const StyledPicture = styled(ProfilePicture)`
    box-shadow: 0 1px 2px 4px rgba(0,0,0,0.1);
`