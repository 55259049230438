import { ContextModel } from "../../model/context.model";

export interface GetContextResponse {
    id: string;
    displayName: string;
    image: string;
}

export function toContextModel(contract: GetContextResponse): ContextModel {
    return {
        userId: contract.id,
        displayName: contract.displayName,
        image: contract.image
    }
}