import React, { useEffect, useState } from 'react';
import { ActionButton } from '../../../../common/components/ActionButton';
import { Button, ButtonSize } from '../../../../common/components/Button';
import { CheckMarkIcon } from '../../../../common/components/icons/CheckMarkIcon';
import { FeatureFlag } from '../../../../common/feature-flag';
import { FEATURES } from '../../../../common/feature-flag/features';
import { createFriendRequest, getFriendRequest } from '../../apis/dataview';
import { ProfileModel } from '../../models/profile.model';
import styled from "styled-components";
import { FriendRequestModel } from '../../models/friend-request.model';
import { Spinner } from '../../../../common/components/Spinner';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { useStats } from '../../../../common/stats';

interface Props {
    profile: ProfileModel;
}

export const FriendRequest: React.FC<Props> = (props: Props) => {
    const { profile } = props;
    const { userId: authenticatedUserId } = useAuthContext()

    const isEnabled = FeatureFlag.isEnabled(FEATURES.FRIEND_REQUESTS);

    if (!isEnabled) {
        return null
    }

    if (profile.id === authenticatedUserId) {
        return null;
    }

    if (profile.isFriend) {
        return (
            <Container>
                <AlreadyFriends profile={profile} />
            </Container>
        );
    }

    return (
        <Container>
            <SendFriendRequest profile={profile} />
        </Container>
    );
}

const buttonStyles = {
}

const AlreadyFriends: React.FC<Props> = (props: Props) => {
    return (
        <SuccessButton
            disabled={true}
            size={ButtonSize.SMALL}
        >
            <ButtonIconContainer>
                <CheckMarkIcon />
            </ButtonIconContainer>
            <span>Friends</span>
        </SuccessButton>
    );
}

const SendFriendRequest: React.FC<Props> = (props: Props) => {
    const { profile } = props;
    const [friendRequestSentSuccessfully, setFriendRequestSentSuccessfully] = useState(false);
    const [loading, setLoading] = useState(true);
    const [friendRequest, setFriendRequest] = useState<null | FriendRequestModel>(null);
    const stats = useStats();

    const onClick = async () => {
        const { friendRequestId } = await createFriendRequest(profile.id);
        stats.trackEvent("Friend Request Created", { friendRequestId: friendRequestId });
    }

    useEffect(() => {
        async function init() {
            const fr = await getFriendRequest(profile.id);
            setFriendRequest(fr);
            setLoading(false);
        }

        init();
    }, [])

    if (loading) {
        return (
            <SuccessButton size={ButtonSize.SMALL}>
                <Spinner />
            </SuccessButton>
        );
    }

    if (friendRequest || friendRequestSentSuccessfully) {
        return (
            <SuccessButton
                disabled={true}
                style={buttonStyles}
                size={ButtonSize.SMALL}
            >
                <ButtonIconContainer>
                    <CheckMarkIcon />
                </ButtonIconContainer>
                <span>Friend Request Sent</span>
            </SuccessButton>
        );
    }

    return (
        <ActionButton
            onClick={onClick}
            onSuccess={() => setFriendRequestSentSuccessfully(true)}
            style={buttonStyles}
            size={ButtonSize.SMALL}
        >
            Send Friend Request
        </ActionButton>
    );
}

const Container = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`;

const ButtonIconContainer = styled.div`
    font-size: 0.9em;
    display: inline-block;
    margin-right: 1ch;
    position: relative;
    top: 1px;
`;

const SuccessButton = styled(Button)`
    background: #cfb993;
    color: #382910 !important;
    box-shadow: unset !important;
    border-top-color: #c0a77c;
    border-bottom-color: #f7e5c6;
`;
