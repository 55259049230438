import React from 'react';
import instagram from './instagram.png';
import styled from "styled-components";

interface Props {

}

export const InstagramIcon: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <Image src={instagram} alt="Instagram Icon" />
        
    );
}

const Image = styled.img`
    height: 1em;
`;