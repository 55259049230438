import { config } from "../config";

export const Urls = {

    getAllCats() {
        return `${config.api.host}/api/v1/cats`;
    },

    login() {
        return `${config.api.host}/api/v1/auth/login`;
    },

    getCityDataview(id: string) {
        return `${config.api.host}/api/v1/cities/${id}`;
    },

    getProfile(id: string) {
        return `${config.api.host}/api/v1/profiles/${id}`;
    },

    getInvitation(id: string) {
        return `${config.api.host}/api/v1/invitations/${id}`;
    },

    getContext() {
        return `${config.api.host}/api/v1/users`;
    },

    acceptFriendshipRequestViaInvitation() {
        return `${config.api.host}/api/v1/friendships`;
    },

    signup() {
        return `${config.api.host}/api/v1/users`;
    },

    getAllCountries() {
        return `${config.api.host}/api/v1/geo/countries`;
    },

    findCityByName(countryCode: string, query: string) {
        return `${config.api.host}/api/v1/geo/cities?countryCode=${countryCode}&q=${query}`;
    },

    getFriendships() {
        return `${config.api.host}/api/v1/friendships`;
    },

    generateInvitationToken() {
        return `${config.api.host}/api/v1/invitations`;
    },

    createFriendRequest() {
        return `${config.api.host}/api/v1/friendships/requests`;
    },

    getFriendRequestsByRecipient(recipientUserId: string) {
        return `${config.api.host}/api/v1/friendships/requests?recipient-user-id=${recipientUserId}`;
    },

    acceptFriendRequest(friendRequestId: string) {
        return `${config.api.host}/api/v1/friendships/requests/${friendRequestId}/accept`;
    },

    getNotificationsPreview() {
        return `${config.api.host}/api/v1/notifications/preview`;
    },

    map: {

        getFriendships() {
            return `${config.api.host}/api/v1/map/friendships`;
        },

    }

}