import React, { useState } from 'react';
import { Button } from '../../../../../common/components';
import { generateInvitationToken } from '../../../api';
import styled from "styled-components";
import whatsapp from './whatsapp.png';
import { useStats } from '../../../../../common/stats';
import { motion, AnimatePresence } from "framer-motion"
import { wait } from '../../../../../common/wait';
interface Props {

}

enum State {
    ACTIVE,
    LOADING,
    READY
}

export const GenerateInvitationLinkSection: React.FC<Props> = (props: Props) => {
    const [state, setState] = useState(State.ACTIVE);
    const [token, setToken] = useState("");
    const stats = useStats();

    const disabled = state === State.LOADING;
    const origin = window.location.origin;
    const url = `${origin}/invite?token=${token}`;
    const whatsappMessage = `Let's be friends on Charlie!\n\n${url}`;

    const onClick = async () => {
        setState(State.LOADING);
        const [t, _] = await Promise.all([generateInvitationToken(), wait(500)]);
        setToken(t);
        stats.trackEvent("Invitation Link Created");

        setState(State.READY);
    }

    return (
        <AnimatePresence>
            {state === State.READY && (
                <motion.div
                    initial={{ opacity: 0, height: state !== State.READY ? 0 : undefined }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: .5, type: "easeInOut" }}
                    style={{ overflow: "hidden" }}
                >
                    <UrlBox>
                        {url}
                    </UrlBox>
                    <LinkContainer>
                        <Link href={`https://api.whatsapp.com/send?text=${encodeURIComponent(whatsappMessage)}`} data-action="share/whatsapp/share">
                            <Image src={whatsapp} />
                            <LinkText>Share via WhatsApp</LinkText>
                        </Link>
                    </LinkContainer>
                    <Note>
                        <strong>Note:</strong><br />
                        <p>This invitation link is personal and can only be used once.<br /></p>
                        <p>If you'd like to invite multiple friends, please create one link per friend.<br /></p>
                        <LinkButton onClick={onClick} disabled={disabled}>Create another link</LinkButton>
                    </Note>
                </motion.div>
            )
            }
            {
                state === State.ACTIVE && (
                    <ButtonContaienr>
                        <Button
                            onClick={onClick}
                            disabled={disabled}
                        >
                            Create invitation link
                        </Button>
                    </ButtonContaienr>
                )
            }
        </AnimatePresence>
    );
}

const ButtonContaienr = styled.div`
    display: flex;
    justify-content: center;
`;

const UrlBox = styled.div`
    font-size: .8rem;
    padding: 2ch;
    line-break: anywhere;
    border-radius: 4px;
    background: rgba(0,0,0,0.1);
    color: #391335;
    border-bottom: 1px solid rgb(255 255 255 / 32%);
    border-top: 1px solid rgba(0,0,0,0.1);
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
`;

const Link = styled.a`
    color: #240d2c;
    text-decoration: none;
    display: inline-block;
`;

const LinkText = styled.span`
    vertical-align: middle;
`

const Image = styled.img`
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 1ch;
`;

const Note = styled.div`
    font-size: 0.85em;
    margin-top: 2rem;
    color: #3c194b;

    strong {
        color: #240d2c;
    }

    p {
        margin: .5em 0;
    }
`;

const LinkButton = styled.button`
    text-decoration: underline;
    color: #240d2c;
    border: 0;
    outline: 0;
    font-family: unset;
    background: none;
    padding: 0;
    margin: 0;
`;