import React from 'react';
import { ShowMore } from '../../../../common/components/ShowMore';
import { RecommendationModel } from '../../models/recommendation.model';
import { Recommendation } from './Recommendation';

interface Props {
    recommendations: RecommendationModel[]
}

export const RecommendationsSection: React.FC<Props> = (props: Props) => {
    const { recommendations } = props;

    return (
        <div>
            <ShowMore
                values={recommendations}
                pageSize={5}
                getKey={getKey}
                component={({ key, value }) => <Recommendation key={key} recommendation={value} />}
            />
        </div>
    );
}

function getKey(r: RecommendationModel): string {
    return `${r.country.countryCode}-${r.city.id}`;
}