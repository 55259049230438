import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Button } from '../../../../common/components';
import { EyeHideIcon } from '../../../../common/components/icons/EyeHideIcon';
import { EyeShowIcon } from '../../../../common/components/icons/EyeShowIcon';
import { MapSection } from '../MapSection';

interface Props {
}

export const HeroSection: React.FC<Props> = (props: Props) => {
    const { } = props;
    const navigate = useNavigate();

    const openMapPage = () => {
        navigate('/map');
    }

    return (
        <Container>
            <Overlay onClick={openMapPage}>
                <Content>
                    <Title>Discover Your Next Destination</Title>
                    <Paragraph>Use our interactive map to find friends around the world.</Paragraph>
                    <Button>Start Exploring!</Button>
                </Content>
            </Overlay>
            <MapSection />
        </Container>
    );
}

const Container = styled.div`
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    position: relative;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(200% 120% at 90% 0%, #07183278 30%, transparent);
    z-index: 2;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    text-align: center;
`;

const Title = styled.h1`
    text-align: center;
    font-size: 38px;
    color: #fff;
    text-shadow: 0 2px 3px rgb(0 0 0 / 70%);
`;

const Paragraph = styled.p`
    width: 70vw;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-size: 18px;
    line-height: 1.7;
    color: #fff;
    text-shadow: 0 2px 3px rgb(0 0 0 / 70%);
`;