const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token'

export function startSession(accessToken: string) {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);

    window.dispatchEvent(new StorageEvent('storage', {
        key: ACCESS_TOKEN_LOCAL_STORAGE_KEY,
        newValue: accessToken
    }));
}


export function stopSession() {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, "");

    window.dispatchEvent(new StorageEvent('storage', {
        key: ACCESS_TOKEN_LOCAL_STORAGE_KEY,
        newValue: ""
    }));
}
