import styled from "styled-components";

export const Row = styled.div`
    label {
        margin-bottom: 8px;
        display: inline-block;
        color: #513a10;
    }

    & + & {
        margin-top: 3rem;
    }
`

export const Center = styled.div`
    text-align: center;
`;

export const TabTitle = styled.h3`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
    color: #513a10;
`   

export const Hint = styled.div`
    color: #a07f44;
    padding: 16px;
`;
