import { useMemo } from 'react';
import { create, test, enforce, only, group, skip } from 'vest';
import { FIELDS, TABS } from './consts';
import { JoinFormData } from './glossary';

export interface ValidationOptions {
    tab?: string;
}

const createSuite = () => create((data: Partial<JoinFormData & ValidationOptions> = {}, currentField: string) => {
    skipTabs();

    group(TABS.DISPLAY_NAME, () => {
        only(currentField);

        test(FIELDS.DISPLAY_NAME, "Please enter your name", () => {
            enforce(data.displayName).isNotBlank();
        });

        test(FIELDS.COUNTRY, "Please select the country you live in", () => {
            enforce(data.country).isNotNullish();
        });

        test(FIELDS.CITY, "Please select the city you live in", () => {
            enforce(data.city).isNotNullish();
        });

    })

    group(TABS.SELF_INTRODUCTION, () => {
        only(currentField);

        test(FIELDS.SELF_INTRODUCTION, "This field is mandatory", () => {
            enforce(data.selfIntroduction).isNotBlank();
        });

    })

    group(TABS.CONTACT_INFO, () => {
        only(currentField);

        test(FIELDS.CONTACT_INFO, "Please enter at least one", () => {
            enforce(data).condition((value: Partial<JoinFormData>) => {
                return notEmpty(value.phoneNumber) || notEmpty(value.instagram)
            });
        });
    })

    group(TABS.PROFILE_PICTURE, () => {
        only(currentField);

        test(FIELDS.PROFILE_PICTURE, "Please upload a picture", () => {
            enforce(data.profilePicture).isNotBlank();
        });
    })

    group(TABS.CREDENTIALS, () => {
        only(currentField);

        test(FIELDS.EMAIL, "Please enter your email address", () => {
            enforce(data.email).isNotBlank();
        });

        test(FIELDS.PASSWORD, "Please enter a password", () => {
            enforce(data.password).isNotBlank();
        });
    });

    function skipTabs() {
        if (data.tab) {
            for (const tab of Object.values(TABS)) {
                if (data.tab !== tab) {
                    skip.group(tab)
                }
            }
        }
    }

})

export const useSuite = () => useMemo(createSuite, []);

function notEmpty(str?: string): boolean {
    if (str === null || str === undefined) {
        return false;
    }

    if (str === "") {
        return false;
    }

    return true;
}