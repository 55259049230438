import React from 'react';
import classes from './index.module.scss';

interface Props {
    children?: React.ReactNode;
    isOpen?: boolean;
}

export const Modal: React.FC<Props> = (props: Props) => {
    const {
        children,
        isOpen
    } = props;

    if (!isOpen) {
        return null;
    }

    return (
        <div className={classes.modal}>
            {children}
        </div>
    );
}
