import React from 'react';
import Map, { Marker, ViewState, ViewStateChangeEvent } from 'react-map-gl';
import { Anchor } from './Anchor';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FriendModel } from './model/friend.model';

interface Props {
    height?: number;
    width?: number;
    viewport: Partial<ViewState>;
    onMove: (evt: ViewStateChangeEvent) => any;
    friends: FriendModel[];
    onFriendClick: (friend: FriendModel) => any;
}

export const WorldMap: React.FC<Props> = (props: Props) => {
    const {
        viewport,
        onMove,
        height,
        width,
        friends,
        onFriendClick
    } = props;

    return (
        <Map
            {...viewport}
            style={{ width, height }}
            onMove={onMove}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            mapStyle="mapbox://styles/mapbox/streets-v12"
        >
            {friends.map((friend, index) => (
                <Marker
                    key={friend.id}
                    latitude={friend.location.latitude}
                    longitude={friend.location.longitude}
                >
                    <Anchor
                        friend={friend}
                        viewport={viewport}
                        onClick={() => onFriendClick(friend)}
                    />
                </Marker>
            ))}
        </Map>
    );
}