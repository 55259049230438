import { FriendModel, LocationModel } from "../../model/friend.model";
import { InvitationModel } from "../../model/invitation.model";

export interface GetInvitationResult {
    invitingUser: {
        displayName: string;
        image: string;
    }
    friends: FriendDto[];
    status: string;
}

export function toInvitationModel(contract: GetInvitationResult): InvitationModel {
    return {
        invitingUser: {
            displayName: contract.invitingUser.displayName,
            image: contract.invitingUser.image
        },
        friends: contract.friends.map(toFriendModel),
        isActive: contract.status === INVITATION_STATUS_DTO.ACTIVE,
    }
}

export interface FriendDto {
    displayName: string;
    image: string;
    location: LocationDto;
}

interface LocationDto {
    country: {
        countryCode: string;
        name: string;
    };
    city: {
        id: number
        name: string;
    }
}

function toFriendModel(contract: FriendDto): FriendModel {
    return {
        displayName: contract.displayName,
        image: contract.image,
        location: toLocationModel(contract.location)
    }
}

function toLocationModel({ country, city }: LocationDto): LocationModel {
    return {
        country: {
            countryCode: country.countryCode,
            name: country.name
        },
        city: {
            id: city.id,
            name: city.name
        }
    }
}

export const INVITATION_STATUS_DTO = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}