import { FriendModel } from "../../models/friend.model";
import { RecommendationModel } from "../../models/recommendation.model";

export interface GetFriendshipsResult {
    friends: FriendDto[];
    recommendations: RecommendationDto[];
}

export interface FriendDto {
    id: string;
    displayName: string;
    image: string;
}

export interface RecommendationDto {
    country: {
        countryCode: string;
        name: string;
    };
    city: {
        id: number;
        name: string;
    };
    connections: number;
};

export interface DataView {
    friends: FriendModel[];
    recommendations: RecommendationModel[];
}

export function toDataView(response: GetFriendshipsResult): DataView {
    return {
        friends: response.friends.map(toFriendModel),
        recommendations: response.recommendations.map(toRecommendationModel)
    };
}

function toFriendModel(f: FriendDto): FriendModel {
    return {
        id: f.id,
        displayName: f.displayName,
        image: f.image
    }
}

function toRecommendationModel(contract: RecommendationDto): RecommendationModel {
    const { country, city } = contract;

    return {
        country: {
            countryCode: country.countryCode,
            name: country.name,
        },
        city: {
            id: city.id,
            name: city.name,
        },
        connections: contract.connections
    }
}