import React from 'react';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { FriendModel } from '../../models/friend.model';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { getSmallImageUrl } from '../../../../common/image';

interface Props {
    friend: FriendModel;
}

export const MutualFriend: React.FC<Props> = (props: Props) => {
    const { friend } = props;

    return (
        <Container>
            <StyledLink to={`/profile/${friend.id}?source=profile-page&source-element=mutual-friends`}>
                <ImageContainer>
                    <ProfilePicture
                        title={friend.displayName}
                        src={getSmallImageUrl(friend.image, 50)}
                    />
                </ImageContainer>
                <Text>{friend.displayName}</Text>
            </StyledLink>
        </Container>
    );
}

const Container = styled.div`
    padding: .5rem 0;
    width: 60vw;
    margin: 0 auto;
`;

const StyledLink = styled(Link)`
    display: inline-block;
    display: flex;
    align-items: center;
    width: max-content;

    text-decoration: none;
    color: #776036;
`;

const Text = styled.span`
    vertical-align: middle;
`

const ImageContainer = styled.div`
    width: 48px;
    vertical-align: middle;
    margin-right: 1.5ch;
`
