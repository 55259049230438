import React from 'react';
import { SuiteRunResult } from 'vest';
import { Validations } from '../../../../../common/components/Validations';
import { FileUploader } from '../../FileUploader';
import { Row } from '../common';
import { FIELDS } from '../consts';
import { JoinFormData } from '../glossary';

interface Props {
    image: string;
    onImageChange: (image: string) => any;

    validate: (formData: Partial<JoinFormData>, field: string) => any;
    validationResult?: SuiteRunResult;
}

export const ProfilePictureStep: React.FC<Props> = (props: Props) => {
    const { image, validationResult } = props;

    const onImageChange = (image: string) => {
        props.validate({ profilePicture: image }, FIELDS.PROFILE_PICTURE);
        props.onImageChange(image);
    }

    return (
        <Row>
            <label htmlFor={FIELDS.PROFILE_PICTURE}>Profile picture</label>
            <FileUploader id={FIELDS.PROFILE_PICTURE} value={image} onChange={onImageChange} />
            <Validations errors={validationResult?.getErrors(FIELDS.PROFILE_PICTURE)} />
        </Row>
    );
}
