import React, { useEffect, useState } from 'react';
import { Layout } from '../../../../common/components/Layout';
import { getFriendships } from '../../api';
import { FriendModel } from '../../models/friend.model';
import { Friend } from '../Friend';
import styled from "styled-components";
import { GenerateInvitationLinkSection } from '../sections/GenerateInvitationLinkSection';
import { RecommendationsSection } from '../RecommendationsSection';
import { RecommendationModel } from '../../models/recommendation.model';
import { useStats } from '../../../../common/stats';
import { Loading } from '../../../../common/components/Loading';
import { ShowMore } from '../../../../common/components/ShowMore';
import { ThinHeader } from '../../../map/components/ThinHeader';
import { HeroSection } from '../HeroSection';

interface Props {
}

export const Entry: React.FC<Props> = (props: Props) => {
    const stats = useStats();
    const [friends, setFriends] = useState<FriendModel[]>();
    const [recommendations, setRecommendations] = useState<RecommendationModel[]>([]);

    useEffect(() => {
        async function init() {
            const data = await getFriendships();
            setFriends(data.friends);
            setRecommendations(data.recommendations);
        }

        stats.trackEvent("Logged-in Home Page Viewed");
        init()
    }, []);

    if (friends === undefined) {
        return (
            <Loading />
        )
    }

    return (
        <Layout showHeader={false}>
            <ThinHeader />

            <div data-testid="logged-in-home-page">

                <HeroSection />

                {(recommendations.length > 0) && (
                    <Section>
                        <SectionTitle>Cities worth visiting</SectionTitle>
                        <RecommendationsSection recommendations={recommendations} />
                    </Section>
                )
                }

                <Section>
                    <SectionTitle>My Friends</SectionTitle>
                    <Friends>
                        <ShowMore
                            values={friends}
                            pageSize={6}
                            getKey={(f: FriendModel) => f.id}
                            component={({ key, value }) => <Friend friend={value} key={key} />}
                        />
                    </Friends>
                </Section>

                <PurpleSection>
                    <SectionTitle>Invite a friend</SectionTitle>
                    <SectionDescription>Having more friends on Charlie will help you meet more cool people when traveling.</SectionDescription>
                    <GenerateInvitationLinkSection />
                </PurpleSection>

            </div>
        </Layout >
    );
}

const SectionTitle = styled.h2`
    color: #3d2604;
    margin-top: 0;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 28px;
`;

const Section = styled.div`
    padding: 5rem 24px;

    &:nth-child(2n) {
        background: #ffe4bc;
    }
`;

const PurpleSection = styled(Section)`
    background: radial-gradient(150% 200% at 90% -10%, #e5a2dd 30%, #9451ca) !important;

    ${SectionTitle} {
        color: #22011e;
    }
`;

const SectionDescription = styled.p`
    width: 70vw;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 3rem;
    font-size: 18px;
    line-height: 1.7;
    color: #39213d;
`;

const Friends = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 -24px;
`;
