import React from 'react';
import { motion } from "framer-motion"
import styled from "styled-components";
import { ProfilePicture } from '../../../../../../common/components/ProfilePicture';
import { FlagIcon } from '../../../../../../common/components/icons/FlagIcon';
import { FriendModel } from '../../../../model/friend.model';
import { getSmallImageUrl } from '../../../../../../common/image';

interface Props {
    friend: FriendModel;
}

const duration = 1;
const easingMode = "spring"

export const FriendExampleStep: React.FC<Props> = (props: Props) => {
    const { friend } = props;
    const { city, country } = friend.location;

    return (
        <>
            <Content
                initial={{ opacity: 0, y: "10px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: .7, type: "easeInOut" }}
            >
                <Title>Traveling to {city.name}?</Title>
                <p>
                    {friend.displayName} will tell you about the nicest spots in town!
                </p>
            </Content>
            <ProfileExample>
                <Picture
                    initial={{ opacity: 0, scale: 0.75 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: duration, type: easingMode, delay: 0.4 }}
                >
                    <ProfilePicture
                        src={getSmallImageUrl(friend.image, 300)}
                        title={friend.displayName}
                    />
                </Picture>

                <Description
                    initial={{ opacity: 0, x: "-10px" }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: duration, type: easingMode, delay: 0.8 }}
                >
                    <FlagIcon countryCode={country.countryCode} size={48} title={`Flag of ${country.name}`} />
                    <DescriptionText>
                        <Bold>{friend.displayName}</Bold>
                        <div>{city.name}, {country.name}</div>
                    </DescriptionText>
                </Description>
            </ProfileExample>
        </>
    );
}

const Title = styled.h2`
    font-size: 28px;
    font-weight: 600;
    color: #442F0F;
`;

const Content = styled(motion.div)`
    font-weight: 400;
    color: #442F0F;
    text-align: center;

    p {
        line-height: 1.5;
        font-size: 20px;
    }

    p + p {
        margin-top: 2em;
    }
`

const ProfileExample = styled.div`
    height: calc(50vw + 3rem);
    position: relative;
    margin-top: 2rem;
`;

const Picture = styled(motion.div)`
    position: absolute;
    width: 50vw;
    right: -10vw;
`

const Description = styled(motion.div)`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
`

const DescriptionText = styled.div`
    flex: 1;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Bold = styled.div`
    font-weight: 600;
`