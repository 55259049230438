import { SignupModel } from "../../model/signup.model";

export interface SignupRequest {
    invitationId: string;

    displayName: string;
    cityId: number;

    image: string;

    email: string;
    password: string;

    selfIntroduction: string;

    contactInfo?: string; // TODO - remove this
    phoneNumber?: string;
    instagram?: string;
}

export function toSignupRequest(formData: SignupModel): SignupRequest {
    const {
        invitationId,
        email,
        password,
        displayName,
        image,
        cityId,
        selfIntroduction,

        phoneNumber,
        instagram
    } = formData;

    return {
        invitationId,
        email,
        password,
        displayName,
        image,
        cityId,
        selfIntroduction,

        phoneNumber: clearEmptyString(phoneNumber),
        instagram: clearEmptyString(instagram),

    };
}

function clearEmptyString(value: string) {
    if (value === "") {
        return undefined;
    }

    return value;
}