import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { startSession } from '../../../../common/auth/accessToken';
import { Button, Input } from '../../../../common/components';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { Layout } from '../../../../common/components/Layout';
import { Loading } from '../../../../common/components/Loading';
import { login } from '../../apis';
import classes from './index.module.scss';

interface Props {
}

export const Entry: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { userId } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const { accessToken} = await login({ email, password });
        startSession(accessToken);
    }

    useEffect(() => {
        if (userId) {
            navigate('/');
        }
    }, [userId, navigate]);

    if (loading || userId) {
        return <Loading />
    }

    return (
        <Layout>
            <form onSubmit={onSubmit} className={classes.form}>
                <h1>Login</h1>
    
                <div className={classes.control}>
                    <label htmlFor="email-input">Email</label>
                    <Input value={email} onChange={setEmail} id="email-input" type="text" />
                </div>
                <div className={classes.control}>
                    <label htmlFor="password-input">Password</label>
                    <Input value={password} onChange={setPassword} id="password-input" type="password" />
                </div>
                <div className={classes.actions}>
                    <Button type="submit">Login</Button>
                </div>
            </form>
        </Layout>
    );
}
