import React from 'react';
import { Button } from '../../../../common/components';
import { Layout } from '../../../../common/components/Layout';
import { Section } from '../../../../common/components/Section';
import styled from "styled-components";

interface Props {

}

export const Entry: React.FC<Props> = (props: Props) => {
    const { } = props;

    return (
        <Layout>
            <Section>
                <Title>The #1 platform for meeting cool people around the world</Title>
            </Section>
            <Section>
                <Actions>
                    <Button href="/login">Login</Button>
                </Actions>
            </Section>
        </Layout>
    );
}


const Title = styled.h1`
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: #2e1d03;
    margin-top: 3rem;
`;

const Actions = styled.div`
    text-align: center;
`;