import { FriendRequestModel } from "../../model/friend-request.model";

export interface GetFriendRequestsResponse {
    requests: FriendRequestDto[];
}

export interface FriendRequestDto {
    id: string;
    requestingUser: {
        id: string;
        displayName: string;
        image: string;
    }
}

export function toFriendRequestModels(contract: GetFriendRequestsResponse): FriendRequestModel[] {
    return contract.requests.map(fr => ({
        id: fr.id,
        sender: {
            id: fr.requestingUser.id,
            displayName: fr.requestingUser.displayName,
            image: fr.requestingUser.image
        }
    }));
}