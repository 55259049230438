
import React from 'react';
import { Portal as ReactPortal } from 'react-portal';

interface Props {
    children: React.ReactNode;
}

export const Portal: React.FC<Props> = (props: Props) => {
    const { children } = props;

    return (
        <ReactPortal node={document && document.getElementById('portal-root')}>
            {children}
        </ReactPortal>
    );
}