import React, { useState } from 'react';
import { Button, ButtonSize } from '../Button';
import styled from "styled-components";
import { Spinner } from '../Spinner';
import { wait } from '../../wait';
import { noop } from 'lodash';


interface Props {
    children?: React.ReactNode;
    onClick: () => Promise<any>;
    onSuccess?: () => any;
    onFailure?: () => any;
    style?: object;
    size?: ButtonSize;
}

export const ActionButton: React.FC<Props> = (props: Props) => {
    const { children, style } = props;
    const [loading, setLoading] = useState(false);

    const onSuccess = props.onSuccess ?? noop;
    const onFailure = props.onFailure ?? noop;

    const onClick = async () => {
        if (loading) {
            return;
        }

        setLoading(true);

        try {
            await Promise.all([props.onClick(), wait(500)]);
            onSuccess();
        } catch(e) {
            onFailure();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Button onClick={onClick} size={props.size} style={style}>
            <Content>
                {loading && <Loader />}
                <ChildrenContainer loading={loading}>{children}</ChildrenContainer>
            </Content>
        </Button>
    );
}

const Loader = () => {
    return (
        <LoaderContainer>
            <Spinner />
        </LoaderContainer>
    );
}

const Content = styled.span`
    position: relative;
    display: inline-block;
`;

const LoaderContainer = styled.div`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ChildrenContainer: React.FC<any> = styled.div`
    opacity: ${(props: any) => props.loading ? 0 : 1}
`;