import React, { useEffect, useState } from 'react';
import { withAuthGuard } from '../../../../common/auth/guards';
import { useAuthContext } from '../../../../common/components/AuthContext';
import { Layout } from '../../../../common/components/Layout';
import { Section } from '../../../../common/components/Section';
import { useStats } from '../../../../common/stats';
import { getFriendRequests } from '../../apis';
import { FriendRequestModel } from '../../model/friend-request.model';
import { FriendRequest } from '../FriendRequest';

interface Props {
}

const _Entry: React.FC<Props> = (props: Props) => {
    const { } = props;
    const stats = useStats()
    const { userId: authenticatedUserId } = useAuthContext();
    const [friendRequests, setFriendRequests] = useState<FriendRequestModel[]>([]);

    useEffect(() => {
        async function init() {
            const fr = await getFriendRequests(authenticatedUserId);
            setFriendRequests(fr);
            stats.trackEvent("Notifications Page Viewed");
        }

        init()
    }, []);

    return (
        <Layout>
            <Section>
                <h1>Notifications</h1>

                <h2>Friend Requests</h2>
                {
                    friendRequests.map(fr => <FriendRequest key={fr.id} friendRequest={fr} />)
                }
            </Section>
        </Layout>
    );
}

export const Entry = withAuthGuard(_Entry);
