import { FEATURES } from "./features";

export const FeatureFlag = {
    isEnabled: (featureName: string): boolean => {
        if (featureName === FEATURES.FRIEND_REQUESTS) {
            return true;
        }

        const flag = localStorage.getItem(featureName);
        return (!!flag);
    }
}