import axios from "axios";
import { authorizationHeaders } from "../../../common/apis/auth";
import { Urls } from "../../../common/apis/urls";
import { CreateInvitationResponse } from "./contracts/create-invitation.response";
import { GetFriendshipsResult, toDataView, DataView } from "./contracts/get-friendships.result";

export async function getFriendships(): Promise<DataView> {
    const { data } = await axios.get<GetFriendshipsResult>(Urls.getFriendships(), authorizationHeaders());
    return toDataView(data)
}

export async function generateInvitationToken(): Promise<string> {
    const { data } = await axios.post<CreateInvitationResponse>(Urls.generateInvitationToken(), {}, authorizationHeaders());
    return data.token;
}
