import React from 'react';
import styled from "styled-components";
import { Button } from '../../../../common/components';
import { FlagIcon } from '../../../../common/components/icons/FlagIcon';
import { Portal } from '../../../../common/components/Portal';
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { getSmallImageUrl } from '../../../../common/image';
import { FriendModel } from '../../models/friend.model';
import { motion, AnimatePresence } from "framer-motion";

interface Props {
    friend?: FriendModel;
    onClose: () => void;
    isOpen: boolean
}

export const ProfilePreviewDrawer: React.FC<Props> = (props: Props) => {
    const { friend, onClose, isOpen } = props;
    const { country, city } = friend?.location ?? {};

    return (
        <Portal>
            <AnimatePresence>
                {
                    !isOpen ?
                        null :
                        <Container
                            initial={{ y: "100%" }}
                            animate={{ y: "0" }}
                            transition={{ duration: .5, type: "spring" }}
                            exit={{ y: "100%" }}
                        >
                            <CloseButton onClick={onClose}>X</CloseButton>

                            <UserInfo>
                                <PictureContainer>
                                    <ProfilePicture src={getSmallImageUrl(friend.image, 100)} title={friend.displayName} />

                                    <FlagContainer>
                                        <FlagIcon
                                            countryCode={country.countryCode}
                                            size={24}
                                            title={`Flag of ${country.name}`}
                                        />
                                    </FlagContainer>

                                </PictureContainer>
                                <UserInfoText>
                                    <DisplayName>{friend.displayName}</DisplayName>
                                    <LocationName>{city.name}, {country.name}</LocationName>
                                </UserInfoText>
                            </UserInfo>

                            <Actions>
                                <Button href={`/profile/${friend.id}?source=map-page&source-element=profile-preview`}>View Profile</Button>
                            </Actions>
                        </Container>
                }
            </AnimatePresence>
        </Portal>
    );
}

const Container = styled(motion.div)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FFDFAF;
    z-index: 2;
    padding: 16px;

    padding-bottom: calc(16px + 50px); // for spring animation
    margin-bottom: -50px;

    box-shadow: 0 5px 10px 10px rgba(0, 0, 0, 27%);
`;

const UserInfo = styled.div`
    display: flex;
`;

const PictureContainer = styled.div`
    width: 20vw;
    margin-right: 1rem;
    position: relative;
`;

const UserInfoText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const DisplayName = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: #3d2604;
`;

const LocationName = styled.div`
    font-size: 0.9rem;
    color: #91733b;
`;

const FlagContainer = styled.div`
    position: absolute;
    bottom: -10%;
    right: -10%;
`;

const Actions = styled.div`
    text-align: center;
    margin-top: 1rem;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;

    background: #F3CA8F;
    border-radius: 100%;
    color: #957B56;
    border: none;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
`;