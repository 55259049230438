import axios from "axios";
import { authorizationHeaders } from "../../../common/apis/auth";
import { Urls } from "../../../common/apis/urls";
import { CityModel } from "../model/geo/city.model";
import { CountryModel } from "../model/geo/country.model";
import { InvitationModel } from "../model/invitation.model";
import { SignupModel } from "../model/signup.model";
import { AcceptFriendshipRequestRequest } from "./contracts/accept-friendship-request.request";
import { FindCityResponse, toCityModel } from "./contracts/find-city.response";
import { GetAllCountriesResponse, toCoutnryModel } from "./contracts/get-all-countries.response";
import { GetInvitationResult, toInvitationModel } from "./contracts/get-invitation.result";
import { SignupRequest, toSignupRequest } from "./contracts/signup-request";
import { SignupResponse } from "./contracts/signup.response";

export async function fetchInvitation(id: string): Promise<InvitationModel> {
    const { data } = await axios.get<GetInvitationResult>(Urls.getInvitation(id));
    return toInvitationModel(data);
}

export async function acceptFriendshipRequest(invitationId: string): Promise<void> {
    const body: AcceptFriendshipRequestRequest = { invitationId };
    await axios.post(Urls.acceptFriendshipRequestViaInvitation(), body, authorizationHeaders())
}

export async function signup(formData: SignupModel): Promise<SignupResponse> {
    const body: SignupRequest = toSignupRequest(formData);
    const { data } = await axios.post<SignupResponse>(Urls.signup(), body);

    return data;
}

export async function fetchCountries(): Promise<CountryModel[]> {
    const { data } = await axios.get<GetAllCountriesResponse>(Urls.getAllCountries());
    return data.countries.map(toCoutnryModel)
}

export async function findCityByName(params: { countryCode: string, query: string }): Promise<CityModel[]> {
    const { data } = await axios.get<FindCityResponse>(Urls.findCityByName(params.countryCode, params.query));
    return data.cities.map(toCityModel)
}