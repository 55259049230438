import React from 'react';
import { FriendModel } from '../../models/friend.model';
import styled from "styled-components"
import { ProfilePicture } from '../../../../common/components/ProfilePicture';
import { getSmallImageUrl } from '../../../../common/image';
import { UnstyledLink } from '../../../../common/components/UnstyledLink';

interface Props {
    friend: FriendModel;
}

export const Friend: React.FC<Props> = (props: Props) => {
    const { friend } = props;
    const profilePageUrl = `/profile/${friend.id}`;

    return (
        <UnstyledLink to={profilePageUrl}>
            <Container>
                <ProfilePictureContainer>
                    <ProfilePicture src={getSmallImageUrl(friend.image, 150)} title={friend.name} />
                </ProfilePictureContainer>

                <Name>{friend.name}</Name>
            </Container>
        </UnstyledLink>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ProfilePictureContainer = styled.div`
    width: 150px;
`;

const Name = styled.div`
    margin-top: .5rem;
    text-align: center;
    font-weight: 600;
    color: #503812;
`;