import { CountryModel } from "../../model/geo/country.model";

export interface CountryDto {
    countryCode: string;
    name: string;
}

export interface GetAllCountriesResponse {
    countries: CountryDto[];
}

export function toCoutnryModel(contract: CountryDto): CountryModel {
    return {
        countryCode: contract.countryCode,
        name: contract.name
    }
}