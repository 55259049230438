import React from 'react';
import { Input } from '../../../../../common/components';
import { CitySearch } from '../../../../../common/components/CitySearch';
import { Option } from '../../../../../common/components/CitySearch/glossary';
import { Row } from '../common';
import styled from "styled-components";
import { SuiteRunResult } from 'vest';
import { JoinFormData } from '../glossary';
import { FIELDS } from '../consts';

interface Props {
    displayName: string;
    onDisplayNameChange: (value: string) => any;
    selectedCountryOption: undefined | Option;
    onSelectedCountryOptionChange: (option: Option) => any;
    selectedCityOption: undefined | Option;
    onSelectedCityOptionChange: (option: Option) => any;

    validate: (formData: Partial<JoinFormData>, field: string) => any;
    validationResult?: SuiteRunResult;
}



export const DisplayNameStep: React.FC<Props> = (props: Props) => {
    const {
        displayName,
        selectedCountryOption,
        onSelectedCountryOptionChange,
        selectedCityOption,
        onSelectedCityOptionChange,
        validationResult
    } = props;
    const data: Partial<JoinFormData> = {
        displayName,
        country: selectedCountryOption,
        city: selectedCityOption
    };

    const onDisplayNameChange = (value: string) => {
        props.onDisplayNameChange(value);

        const nextData = { ...data, displayName: value };
        props.validate(nextData, FIELDS.DISPLAY_NAME);
    }

    return (
        <div>
            <Row>
                <label htmlFor={FIELDS.DISPLAY_NAME}>What's your name?</label>
                <Input id={FIELDS.DISPLAY_NAME}
                    value={displayName}
                    onChange={onDisplayNameChange}
                    onBlur={() => props.validate(data, FIELDS.DISPLAY_NAME)}
                    errors={validationResult?.getErrors(FIELDS.DISPLAY_NAME)}
                />
            </Row>

            <Row>
                <label>Where do you <i>currently</i> live?</label><br />
                <Spacer />
                <CitySearch
                    selectedCountryOption={selectedCountryOption}
                    onSelectedCountryOption={onSelectedCountryOptionChange}
                    selectedCityOption={selectedCityOption}
                    onSelectedCityOption={onSelectedCityOptionChange}
                    validate={props.validate}
                    validationResult={validationResult}
                />
            </Row>
        </div>
    );
}

const Spacer = styled.div`
    margin-bottom: 1rem;
`;