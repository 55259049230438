import { FriendModel } from "../../../models/friend.model";
import { ContactInfoModel, ProfileModel } from "../../../models/profile.model";

export interface DataViewResult {
    id: string;
    displayName: string;
    image: string;
    selfIntroduction: string;

    contactInfo?: string;
    structuredContactInfo?: ContactInfoDto;

    location: {
        country: {
            countryCode: string;
            name: string;
        };
        city: {
            id: number;
            name: string;
        };
    };
    mutualFriends: FriendDto[];
    isFriend: boolean;
}

interface ContactInfoDto {
    phoneNumber?: string;
    instagram?: string;
}

export interface FriendDto {
    id: string;
    displayName: string;
    image: string;
}


export function toProfileModel(contract: DataViewResult): ProfileModel {
    const { country, city } = contract.location;

    return {
        id: contract.id,
        displayName: contract.displayName,
        image: contract.image,
        selfIntroduction: contract.selfIntroduction,

        contactInfo: contract.contactInfo,
        structuredContactInfo: toContactInfoMode(contract.structuredContactInfo),

        location: {
            country: {
                countryCode: country.countryCode,
                name: country.name,
            },
            city: {
                id: city.id,
                name: city.name,
            },
        },
        mutualFriends: contract.mutualFriends.map(toFriendModel),
        isFriend: contract.isFriend ?? false
    }
}

export function toFriendModel(contract: FriendDto): FriendModel {
    return {
        id: contract.id,
        displayName: contract.displayName,
        image: contract.image
    }
}

function toContactInfoMode(contract?: ContactInfoDto): ContactInfoModel {
    if (!contract) {
        return {};
    }

    return {
        phoneNumber: contract.phoneNumber,
        instagram: contract.instagram
    }
}