import styled from "styled-components";
import { Button } from "../Button";

export const GhostButton = styled(Button)`
    background: none;
    justify-content: space-between;
    color: #916a31;
    font-weight: normal;
    box-shadow: unset;
    border-top: none;
    border-bottom: none;
`