import _ from 'lodash';
import { FriendModel, LocationModel } from "../../models/friend.model";

export interface GetFriendshipsResult {
    friends: FriendDto[];
}

export interface FriendDto {
    id: string;
    displayName: string;
    image: string;
    location: LocationDto;
}

export interface LocationDto {
    latitude: number;
    longitude: number;
    country: {
        countryCode: string;
        name: string;
    };
    city: {
        id: number
        name: string;
    }
}

export function toModel(contract: GetFriendshipsResult): FriendModel[] {
    return _.shuffle(contract.friends).map(toFriendModel)
}

function toFriendModel(contract: FriendDto): FriendModel {
    return {
        id: contract.id,
        displayName: contract.displayName,
        image: contract.image,
        location: toLocationModel(contract.location)
    }
}

function toLocationModel(contract: LocationDto): LocationModel {
    const { country, city } = contract;

    return {
        latitude: addNoise(contract.latitude),
        longitude: addNoise(contract.longitude),
        country: {
            countryCode: country.countryCode,
            name: country.name
        },
        city: {
            id: city.id,
            name: city.name
        }
    }
}

const ONE_KM_IN_COOR_DEGRES = 0.006911;
const SHIFT_BY_KM = 3;

function addNoise(value: number): number {
    const noise = ONE_KM_IN_COOR_DEGRES * SHIFT_BY_KM * random(-1, 1);
    return value + noise;
}

function random(min, max): number {
    return Math.random() * (max - min + 1) + min;
}
