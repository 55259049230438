import React from 'react';
import { Section } from '../../../../common/components/Section';
import styled from "styled-components";
import { MutualFriend } from './MutualFriend';

interface Props {
    mutualFriends: any[];
}

export const MutualFriendsSection: React.FC<Props> = (props: Props) => {
    const { mutualFriends } = props;

    if (mutualFriends.length === 0) {
        return;
    }

    return (
        <Section>
            <Title>Mutual Friends</Title>
            {mutualFriends.map(f => <MutualFriend friend={f} key={f.id} />)}
        </Section>
    );
}



const Title = styled.h3`
    font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    color: #4b371b;
    font-weight: 600;
`;