import React from 'react';
import { motion } from "framer-motion"
import styled from "styled-components";
import { ProfilePicture } from '../../../../../../common/components/ProfilePicture';
import { InvitationModel } from '../../../../model/invitation.model';
import { FlagIcon } from '../../../../../../common/components/icons/FlagIcon';
import { getSmallImageUrl } from '../../../../../../common/image';

interface Props {
    invitation: InvitationModel;
}

const duration = 0.7;
const easingMode = "spring"

export const ConnectingFriendsStep: React.FC<Props> = (props: Props) => {
    const { invitation } = props;
    const [friend1, friend2, friend3] = invitation.friends;

    return (
        <>
            <Content
                initial={{ opacity: 0, y: "10px" }}
                animate={{ opacity: 1, y: "0" }}
                transition={{ duration: .7, type: "easeInOut" }}
            >
                <p>
                    Joining Charlie helps you do just that, by connecting you with friends of friends.
                </p>
                <p>
                    Here are a few of {invitation.invitingUser.displayName}’s friends who are already part of Charlie’s community.
                </p>
            </Content>

            <Friends>
                {
                    friend1 && (
                        <Friend
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: duration, type: easingMode, delay: 0 }}
                        >
                            <PictureContainer>
                                <ProfilePicture
                                    src={getSmallImageUrl(friend1.image, 150)}
                                    title={friend1.displayName}
                                />
                                <FlagContainer>
                                    <FlagIcon
                                        countryCode={friend1.location.country.countryCode}
                                        title={`Flag of ${friend1.location.country.name}`}
                                        size={36}
                                    />
                                </FlagContainer>
                            </PictureContainer>
                            <DisplayName>{friend1.displayName}</DisplayName>
                        </Friend>
                    )}
                {friend2 && (
                    <Friend
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: duration, type: easingMode, delay: 0.1 }}
                    >
                        <PictureContainer>
                            <ProfilePicture
                                src={getSmallImageUrl(friend2.image, 150)}
                                title={friend2.displayName}
                            />
                            <FlagContainer>
                                <FlagIcon
                                    countryCode={friend2.location.country.countryCode}
                                    title={`Flag of ${friend2.location.country.name}`}
                                    size={36}
                                />
                            </FlagContainer>
                        </PictureContainer>
                        <DisplayName>{friend2.displayName}</DisplayName>
                    </Friend>
                )}
                {friend3 && (
                    <Friend
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: duration, type: easingMode, delay: 0.2 }}
                    >
                        <PictureContainer>
                            <ProfilePicture
                                src={getSmallImageUrl(friend3.image, 150)}
                                title={friend3.displayName}
                            />
                            <FlagContainer>
                                <FlagIcon
                                    countryCode={friend3.location.country.countryCode}
                                    title={`Flag of ${friend3.location.country.name}`}
                                    size={36}
                                />
                            </FlagContainer>
                        </PictureContainer>
                        <DisplayName>{friend3.displayName}</DisplayName>
                    </Friend>
                )}
            </Friends>
        </>
    );
}

const Content = styled(motion.div)`
    font-weight: 400;
    color: #442F0F;
    text-align: center;

    p {
        line-height: 1.5;
        font-size: 18px;
    }

    p + p {
        margin-top: 2em;
    }
`

const Friends = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const Friend = styled(motion.div)`
    width: 33vw;
    margin: 1rem;
`

const PictureContainer = styled.div`
    position: relative;
`;

const DisplayName = styled.div`
    text-align: center;
    margin-top: .5rem;
`;

const FlagContainer = styled.div`
    position: absolute;
    bottom: -2px;
    right: -8px;
    height: 36px;
`