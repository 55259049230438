import { FriendRequestCreationResult } from "../../../models/friend-request-creation-result.model";

export interface CreateFriendRequestRequest {
    recipientUserId: string;
}

export interface CreateFriendRequestResponse {
    id: string;
}

export function toFriendRequestCreationResult(contract: CreateFriendRequestResponse): FriendRequestCreationResult {
    return {
        friendRequestId: contract.id
    }
}